import { gql } from 'apollo-boost'

export const GET_VIEWDATABATCHES = gql`
  query ViewDataBatches($farmcode: String, $from: Date, $to: Date) {
    viewdatabatches(farmcode: $farmcode, from: $from, to: $to) {
      id
      CreatedBy
      CreatedOn
      FarmCode
      BatchCode
      NetCode
      FishfeedCode
      Qty
      Pcs
      CostPerKg
      CalculatedPcs
      AllocatedRm
    }
  }
`
