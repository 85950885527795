import { gql } from 'apollo-boost'

export const GET_SPECIES = gql`
  {
    species {
      id
      species_id
      species_code
      species_name
      hexcolor
      color_r
      color_g
      color_b
      species_active
    }
  }
`

export const INSERT_SPECIES = gql`
  mutation InsertSpecies(
    $species_code: String
    $species_name: String
    $color_r: String
    $color_g: String
    $color_b: String
  ) {
    insertspecies(
      species_code: $species_code
      species_name: $species_name
      color_r: $color_r
      color_g: $color_g
      color_b: $color_b
    ) {
      id
      species_id
      species_code
      species_name
      hexcolor
      color_r
      color_g
      color_b
      species_active
    }
  }
`
export const UPDATE_SPECIES = gql`
  mutation UpdateSpecies(
    $id: ID
    $species_code: String
    $species_name: String
    $color_r: String
    $color_g: String
    $color_b: String
  ) {
    updatespecies(
      id: $id
      species_code: $species_code
      species_name: $species_name
      color_r: $color_r
      color_g: $color_g
      color_b: $color_b
    ) {
      id
      species_id
      species_code
      species_name
      hexcolor
      color_r
      color_g
      color_b
      species_active
    }
  }
`