import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import { GET_CATEGORIES } from '../graphql/category'
import { CREATE_FARM, UPDATE_FARM, GET_FARMS } from '../graphql/farm'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default function ProductDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_FARMS,
    })
    const latest = data.insertfarm
    cache.writeQuery({
      query: GET_FARMS,
      data: { farms: [latest, ...old.farms] },
    })

    props.setOpen(false)
    props.setSelected([latest.id])
    props.setFarm(latest)
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_FARMS,
    })
    const latest = data.updatefarm
    const foundIndex = old.farms.findIndex((item) => item.id === latest.id)
    old.farms.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_FARMS,
      data: { farms: [...old.farms] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setFarm()
  }

  /* const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRODUCTS,
    })
    if (data.removeproduct) {
      const latest = old.products.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: { products: [...latest] },
      })
      props.setSelected([])
      props.setProduct()
    }
  } */

  const [insertFarm] = useMutation(CREATE_FARM, { update: addCache })
  const [updateFarm] = useMutation(UPDATE_FARM, {
    update: updateCache,
  })
  /*  const [removeProduct] = useMutation(REMOVE_PRODUCT, {
    update: deleteCache,
  }) */

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateFarm({
        variables: {
          id: props.data.id,
          FarmCode: values.FarmCode,
          FarmName: values.FarmName,
          FarmDesc: values.FarmDesc,
          RowPrefix: values.RowPrefix,
          TotalRows: parseInt(values.TotalRows),
          Cols: parseInt(values.Cols),
          ColsA: parseInt(values.ColsA),
          ColsB: parseInt(values.ColsB),
        },
      })
    } else {
      insertFarm({
        variables: {
          FarmCode: values.FarmCode,
          FarmName: values.FarmName,
          FarmDesc: values.FarmDesc,
          RowPrefix: values.RowPrefix,
          TotalRows: parseInt(values.TotalRows),
          Cols: parseInt(values.Cols),
          ColsA: parseInt(values.ColsA),
          ColsB: parseInt(values.ColsB),
        },
      })
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="farm-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="farm-dialog">
            {props.data && 'Update Farm'}
            {!props.data && 'New Farm'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <FormControl error={errors.FarmCode && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="FarmCode"
                    >
                      Farm Code
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="FarmCode"
                      name="FarmCode"
                      label="FarmCode"
                      defaultValue={props.data && props.data.FarmCode}
                      fullWidth
                      margin="dense"
                      autoComplete="off"
                      inputProps={{
                        maxLength: 200,
                      }}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {/* {errors.FarmCode && errors.FarmCode.message} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {/* <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <FormControl error={errors.FarmName && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="FarmName"
                    >
                      Farm Name
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="FarmName"
                      name="FarmName"
                      label="FarmName"
                      fullWidth
                      margin="dense"
                      autoComplete="off"
                      defaultValue={props.data && props.data.FarmName}
                      inputProps={{
                        maxLength: 200,
                      }}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {errors.FarmName && errors.FarmName.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FormControl error={errors.FarmDesc && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="FarmDesc"
                    >
                      Description
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="FarmDesc"
                      name="FarmDesc"
                      label="FarmDesc"
                      fullWidth
                      margin="dense"
                      autoComplete="off"
                      defaultValue={props.data && props.data.FarmDesc}
                      inputProps={{
                        maxLength: 200,
                      }}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {errors.FarmDesc && errors.FarmDesc.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid> */}

              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <FormControl error={errors.TotalRows && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="TotalRows"
                    >
                      Total Rows
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="TotalRows"
                      name="TotalRows"
                      label="TotalRows"
                      fullWidth
                      type="number"
                      inputProps={{ min: '0', step: 'any' }}
                      margin="dense"
                      autoComplete="off"
                      defaultValue={props.data && props.data.TotalRows}
                      inputProps={{
                        maxLength: 200,
                      }}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {errors.TotalRows && errors.TotalRows.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={6}>
                  {/* <span style={{ display: 'block', marginTop: 25 }}>
                    1st number of a farm cell, eg: A
                    <span style={{ color: 'red', fontWeight: 'bold' }}>10</span>
                    -1
                  </span> */}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <FormControl error={errors.ColsA && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="ColsA"
                    >
                      Total Columns for A Side
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="ColsA"
                      name="ColsA"
                      label="ColsA"
                      fullWidth
                      type="number"
                      inputProps={{ min: '0', step: 'any' }}
                      margin="dense"
                      autoComplete="off"
                      defaultValue={props.data && props.data.ColsA}
                      inputProps={{
                        maxLength: 200,
                      }}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {errors.ColsA && errors.ColsA.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={6}>
                  {/* <span style={{ display: 'block', marginTop: 25 }}>
                    2st number of a farm cell, eg: A10-
                    <span style={{ color: 'red', fontWeight: 'bold' }}>1</span>
                  </span> */}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <FormControl error={errors.ColsB && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="ColsB"
                    >
                      Total Columns for B Side
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="ColsB"
                      name="ColsB"
                      label="ColsB"
                      fullWidth
                      type="number"
                      inputProps={{ min: '0', step: 'any' }}
                      margin="dense"
                      autoComplete="off"
                      defaultValue={props.data && props.data.ColsB}
                      inputProps={{
                        maxLength: 200,
                      }}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {errors.ColsB && errors.ColsB.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={6}>
                  {/* <span style={{ display: 'block', marginTop: 24 }}>
                    2st number of a farm cell, eg: A10-
                    <span style={{ color: 'red', fontWeight: 'bold' }}>1</span>
                  </span> */}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <React.Fragment>
                {/* <IconButton
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setConfirm(true)
                    }}
                    edge="end"
                    aria-label="comments"
                  >
                    <DeleteIcon />
                  </IconButton> */}
                <Button
                  margin="dense"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </React.Fragment>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
