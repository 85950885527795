import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'

import {
  GET_BATCH_STOCKS,
  CREATE_BATCH_STOCK,
  UPDATE_BATCH_STOCK,
} from '../graphql/batch'

import useForm from 'react-hook-form'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'

//const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    marginTop: 8,
    fontWeight: 'bold!important',
    'label + &': {
      fontWeight: 'bold!important',
      marginTop: theme.spacing(3),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px',
    paddingBottom: '10px!important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const deleteSelectItems = [
  { val: 'S', title: 'S-Sales' },
  { val: 'M', title: 'M-Mortality' },
  { val: 'IM', title: 'IM-Invisible Mortality' },
  { val: 'O', title: 'O-Others' },
]

export default function NetBatchStockDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [reasonName, setReasonName] = useState()
  const [transactionType, setTransactionType] = useState()
  const [reason, setReason] = useState()
  const [avgSize, setAvgSize] = useState()
  const [qty, setQty] = useState('')
  const [kg, setKg] = useState('')
  const [remarks, setRemarks] = useState()
  const [to, setTo] = useState(null)
  const [salesType, setSalesType] = useState()

  const [reasonType, setReasonType] = useState()

  useEffect(() => {
    console.log('stock', props)
    setTransactionType(() => props?.stock?.TransactionType)
    setReasonName(() => props?.stock?.Reason)
    setAvgSize(() => props?.stock?.AvgSize)
    setTo(() => props?.stock?.StockTakeDate)
    setReasonType(() => props?.stock?.ReasonType)
    setQty(() => props?.stock?.Qty)
    setRemarks(() => props?.stock?.Remarks)
    setSalesType(() => props?.stock?.SalesType)

    if (!props?.stock) {
      setTransactionType('REDUCE')
      setReasonName('Mortality')
      setReasonType('S')
      setQty('')
      setAvgSize('')
      setSalesType('FRESH_FISH')
      setTo(null)
    }
  }, [props])

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_BATCH_STOCKS,
      variables: {
        BatchID: props?.batchID,
        FarmCode: props?.farmCode,
        NetCode: props?.netCode,
      },
    })
    const latest = data.createbatchstock
    /*  if (latest.Error) {
      enqueueSnackbar(latest.Error, {
        variant: 'error',
      })
      return
    } */
    cache.writeQuery({
      query: GET_BATCH_STOCKS,
      variables: { BatchID: props?.batchID },
      data: { batchstocks: [...old.batchstocks, latest] },
    })
    props.setOpen(false)
    props.refetch()
    props.refetchNetBatches()

    props.onSuccess()
  }

  const [createbatchstock] = useMutation(CREATE_BATCH_STOCK, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_BATCH_STOCKS,
      variables: {
        BatchID: props?.batchID,
        FarmCode: props?.farmCode,
        NetCode: props?.netCode,
      },
    })
    const latest = data.updatebatchstock
    /*  if (latest.Error) {
      enqueueSnackbar(latest.Error, {
        variant: 'error',
      })
      return
    } */
    cache.writeQuery({
      query: GET_BATCH_STOCKS,
      variables: { BatchID: props?.batchID },
      data: { batchstocks: [...old.batchstocks, latest] },
    })
    props.setOpen(false)
    props.refetch()
    props.refetchNetBatches()
    props.onSuccess()
  }

  const [updatebatchstock] = useMutation(UPDATE_BATCH_STOCK, {
    update: updateCache,
  })

  const onSubmit = (values) => {
    /* if (stockItems.length < 1) {
      alert('Please add product')
      return
    } */

    setDisableSubmit(true)
    console.log(values)

    if (props?.stock?.id) {
      updatebatchstock({
        variables: {
          id: props && props?.stock?.id,
          FarmCode: props && props.farmCode,
          NetCode: props && props.netCode,
          BatchID: props && props.batchID,
          BatchCode: props && props.batchCode,
          TransactionType: transactionType,
          Qty: parseInt(values.Qty || 0),
          StockTakeDate: to,
          /* Kg: parseFloat(values.Kg), */
          ReasonType: transactionType === 'REDUCE' ? reasonType : null,
          AvgSize: parseFloat(values.AvgSize),
          Reason: reasonName,
          Remarks: values.Remarks,
          SalesType: transactionType === 'REDUCE' && reasonType === 'S' ? salesType : undefined,
        },
      })
    } else {
      createbatchstock({
        variables: {
          FarmCode: props && props.farmCode,
          NetCode: props && props.netCode,
          BatchID: props && props.batchID,
          BatchCode: props && props.batchCode,
          TransactionType: transactionType,
          Qty: parseInt(values.Qty || 0),
          StockTakeDate: to,
          /* Kg: parseFloat(values.Kg), */
          ReasonType: transactionType === 'REDUCE' ? reasonType : null,
          AvgSize: parseFloat(values.AvgSize),
          Reason: reasonName,
          Remarks: values.Remarks,
          SalesType: transactionType === 'REDUCE' && reasonType === 'S' ? salesType : undefined,
        },
      })
    }
  }

  useEffect(() => {
    setDisableSubmit(false)
  }, [props])

  return (
    <React.Fragment>
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Update Stock
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <ButtonGroup
                      style={{ marginTop: 0 }}
                      variant="contained"
                      color="primary"
                    >
                      <Button
                        disabled={props?.stock?.id && transactionType !== 'ADD'}
                        color={
                          transactionType === 'ADD' ? 'primary' : 'secondary'
                        }
                        onClick={() => {
                          setTransactionType(() => 'ADD')
                          setReasonName(() => 'New Stock')
                          setReason(() => 'NEW_STOCK')
                        }}
                      >
                        Add Stock
                      </Button>
                      <Button
                        disabled={
                          props?.stock?.id && transactionType !== 'REDUCE'
                        }
                        color={
                          transactionType === 'REDUCE' ? 'primary' : 'secondary'
                        }
                        onClick={() => {
                          setTransactionType(() => 'REDUCE')
                          setReasonName(() => 'Mortality')
                          setReason(() => 'MORTALITY')
                        }}
                      >
                        Reduce Stock
                      </Button>
                      <Button
                        disabled={
                          props?.stock?.id && transactionType !== 'SIZING'
                        }
                        color={
                          transactionType === 'SIZING' ? 'primary' : 'secondary'
                        }
                        onClick={() => {
                          setTransactionType(() => 'SIZING')
                          setReasonName(() => 'Sizing')
                          setReason(() => 'SIZING')
                        }}
                      >
                        Update Sizing
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  {/* <Grid item md={6} xs={6}>
                    <AutocompleteField
                      name="Reason"
                      label="Transaction Type"
                      value={reasonName}
                      options={dropdown.map((item) => `${item.title}`)}
                      onChange={(e, value) => {
                        const reasonValue = dropdown.find(
                          (item) => `${item.title}` === value
                        )
                        if (reasonValue) {
                          setReason(reasonValue.id)
                          setReasonName(`${reasonValue.title}`)
                        }
                      }}
                    />
                  </Grid> */}
                </Grid>

                <Grid container spacing={3}>
                  <Grid item sm={5} xs={12}>
                    <DatepickerField
                      name="To"
                      label="Stock Date"
                      value={to ? to : null}
                      fullWidth
                      onChange={(e, value) => {
                        setTo(() => value)
                      }}
                      inputRef={register({
                        required: 'Required',
                      })}
                      autoComplete="off"
                      error={errors.To}
                      helperText={errors.To && errors.To.message}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  {transactionType != 'SIZING' && (
                    <Grid item md={3} xs={12}>
                      <FormControl
                        error={errors.FishfeedCode && true}
                        fullWidth
                      >
                        <InputLabel
                          classes={{ root: classes.rootInputlabel }}
                          shrink
                          htmlFor="Qty"
                        >
                          Pcs
                        </InputLabel>
                        <InputBase
                          classes={{
                            root: classes.rootBootstrap,
                            input: classes.inputBootstrap,
                          }}
                          id="Qty"
                          name="Qty"
                          label="Qty"
                          fullWidth
                          margin="dense"
                          autoComplete="off"
                          value={qty}
                          inputProps={{
                            maxLength: 200,
                          }}
                          onChange={(e, value) => {
                            setQty(() => value)
                          }}
                          inputRef={register({
                            required:
                              transactionType != 'SIZING'
                                ? 'This field is Required'
                                : undefined,
                          })}
                        />

                        <FormHelperText>
                          {/* {errors.FishfeedCode && errors.FishfeedCode.message} */}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  {/* <Grid item md={3} xs={12}>
                    <FormControl error={errors.FishfeedName && true} fullWidth>
                      <InputLabel
                        classes={{ root: classes.rootInputlabel }}
                        shrink
                        htmlFor="Kg"
                      >
                        KG
                      </InputLabel>
                      <InputBase
                        classes={{
                          root: classes.rootBootstrap,
                          input: classes.inputBootstrap,
                        }}
                        id="Kg"
                        name="Kg"
                        label="Kg"
                        fullWidth
                        margin="dense"
                        autoComplete="off"
                        inputProps={{
                          maxLength: 200,
                        }}
                        inputRef={register({})}
                      />

                      <FormHelperText>
                        {errors.Kg && errors.Kg.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}
                  <Grid item md={3} xs={12}>
                    <FormControl error={errors.FishfeedCode && true} fullWidth>
                      <InputLabel
                        classes={{ root: classes.rootInputlabel }}
                        shrink
                        htmlFor="AvgSize"
                      >
                        Avg. Size (Kg)
                      </InputLabel>
                      <InputBase
                        classes={{
                          root: classes.rootBootstrap,
                          input: classes.inputBootstrap,
                        }}
                        id="AvgSize"
                        name="AvgSize"
                        label="AvgSize"
                        fullWidth
                        value={avgSize}
                        margin="dense"
                        autoComplete="off"
                        onChange={(e, value) => {
                          setAvgSize(() => value)
                        }}
                        inputProps={{
                          maxLength: 200,
                        }}
                        inputRef={register({
                          required:
                            transactionType == 'SIZING'
                              ? 'This field is Required'
                              : undefined,
                        })}
                      />

                      <FormHelperText>
                        {/* {errors.FishfeedCode && errors.FishfeedCode.message} */}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {transactionType == 'REDUCE' && (
                    <>
                      <Grid item md={3} xs={12}>
                        <FormControl
                          /* error={errors.Feed && true} */ fullWidth
                        >
                          <InputLabel
                            classes={{ root: classes.rootInputlabel }}
                            shrink
                            htmlFor="ReasonType"
                          >
                            Type
                          </InputLabel>
                          <Select
                            labelId="ReasonType"
                            id="ReasonType"
                            name="ReasonType"
                            value={reasonType}
                            onChange={(e) => {
                              setReasonType(() => e.target.value)
                            }}
                            input={
                              <InputBase
                                classes={{
                                  root: classes.rootBootstrap,
                                  input: classes.inputBootstrap,
                                }}
                              />
                            }
                          >
                            {deleteSelectItems.map((a, i) => (
                              <MenuItem key={i} value={a.val}>
                                {a.title}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {/* {errors.Feed && errors.Feed.message} */}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      {reasonType === 'S' && (
                        <Grid item md={3} xs={12}>
                          <FormControl
                            /* error={errors.Feed && true} */ fullWidth
                          >
                            <InputLabel
                              classes={{ root: classes.rootInputlabel }}
                              shrink
                              htmlFor="SalesType"
                            >
                              Sales Type
                            </InputLabel>
                            <Select
                              labelId="SalesType"
                              id="SalesType"
                              name="SalesType"
                              value={salesType}
                              onChange={(e) => {
                                setSalesType(() => e.target.value)
                              }}
                              input={
                                <InputBase
                                  classes={{
                                    root: classes.rootBootstrap,
                                    input: classes.inputBootstrap,
                                  }}
                                />
                              }
                            >
                              <MenuItem value="LIVE_FISH">Live Fish</MenuItem>
                              <MenuItem value="FRESH_FISH">Fresh Fish</MenuItem>
                            </Select>
                            <FormHelperText>
                              {/* {errors.Feed && errors.Feed.message} */}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>

                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <FormControl error={errors.FishfeedCode && true} fullWidth>
                      <InputLabel
                        classes={{ root: classes.rootInputlabel }}
                        shrink
                        htmlFor="Remarks"
                      >
                        Remarks
                      </InputLabel>
                      <InputBase
                        classes={{
                          root: classes.rootBootstrap,
                          input: classes.inputBootstrap,
                        }}
                        id="Remarks"
                        name="Remarks"
                        label="Remarks"
                        multiline
                        value={remarks}
                        fullWidth
                        onChange={(e, value) => {
                          setRemarks(() => value)
                        }}
                        margin="dense"
                        autoComplete="off"
                        inputProps={{
                          maxLength: 200,
                        }}
                        inputRef={register({})}
                      />

                      <FormHelperText>
                        {/* {errors.FishfeedCode && errors.FishfeedCode.message} */}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                {/* <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton> */}
                <Button
                  disabled={disableSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
