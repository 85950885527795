import React, { useState, useEffect } from 'react'
import {
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Link,
} from 'react-router-dom'

import clsx from 'clsx'

import Cookies from 'js-cookie'
import Login from './Login'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBox,
  faFish,
  faPrint,
  faChartLine,
  faUserTie,
  faTruck,
  faPlane,
  faFileInvoiceDollar,
  faCog,
  faCalculator,
  faPowerOff,
  faFileAlt,
  faFolderOpen,
  faDollarSign,
  faBook,
} from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import './App.css'

import {
  fade,
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/Mail'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import RestoreIcon from '@material-ui/icons/Restore'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'

import Tooltip from '@material-ui/core/Tooltip'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import AppSearch from './AppSearch'

import { SnackbarProvider } from 'notistack'
import Logout from './Logout'

import BasicSettings from './components/admin/basicsettings'
import ManageMain from './components/settings/manage'
import FarmMain from './components/settings/farmmain'
import NetMain from './components/settings/netmain'
import ViewData from './components/settings/viewdata'
import ViewDataMain from './components/settings/viewdatamain'
import ViewDataBatchMain from './components/settings/viewdatabatchmain'
import FeedMain from './components/settings/feedmain'
import StaffMain from './components/settings/staffmain'

import SettingsMain from './components/settings/settings'
import BatchMain from './components/settings/batchmain'
import Report from './components/settings/report'
import SalesMain from './components/settings/salesmain'

const GET_DARKMODE = gql`
  {
    isDarkModeEnabled @client
  }
`
const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  link: { color: '#37474f', textDecoration: 'none' },
  inline: {
    display: 'inline',
    color: '#37474f',
  },
  drawer: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  drawerOpen: {
    background: '#cfd8dc!important',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: '#cfd8dc!important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    padding: theme.spacing(6, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7) + 1,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  iconList: {
    color: '#757575',
    minWidth: '26px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
}))

export default function App(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [signedIn, setSignedIn] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [redirectTo, setRedirectTo] = useState()

  const [open, setOpen] = useState(false)

  const [theme, setTheme] = useState({
    transitions: { create: () => 'none' },
    palette: {
      primary: {
        dark: '#263238',
        main: '#263238',
        paper: '#efefef',
        contrastText: '#fff',
      },
      secondary: {
        main: '#ddd',
      },
      background: {
        default: '#efefef',
      },
      type: 'light', // Switching the dark mode on is a single property value change.
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 12,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  })

  const classes = useStyles()

  const theme2 = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [dense, setDense] = React.useState(false)
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const {
    data: { isDarkModeEnabled, loginDetails } = {
      isDarkModeEnabled: false,
    },
    client,
  } = useQuery(GET_DARKMODE)

  const handleToggle = () => {
    toggleDarkTheme(!isDarkModeEnabled)
    setDense(!isDarkModeEnabled)
    client.writeData({ data: { isDarkModeEnabled: !isDarkModeEnabled } })
  }

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const sideList = (side) => (
    <div>
      <div>
        <IconButton onClick={handleDrawerClose}>
          {theme2.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      {/* 
      <Divider className={classes.divider} />
      <Grid item xs={12} md={12}>
        <div className={classes.demo}>
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <div
                  className={classes.userIcon}
                  dangerouslySetInnerHTML={{
                    __html: `<svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" style="enable-background:new 0 0 100 256;" version="1.1" viewBox="0 30 250 256" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><path class="st6" d="M93.09,126.1c0,0-0.01,0-0.01,0c-16.82,0-30.51-13.68-30.51-30.51c0-16.82,13.69-30.51,30.51-30.51
                            s30.51,13.69,30.51,30.51c0,5.3-1.38,10.51-3.98,15.07c-2.01,3.54-0.78,8.04,2.76,10.06c3.54,2.01,8.04,0.78,10.05-2.76
                            c3.87-6.78,5.91-14.52,5.91-22.37c0-24.95-20.3-45.26-45.26-45.26s-45.26,20.3-45.26,45.26c0,24.95,20.29,45.25,45.23,45.26
                            c0.01,0,0.01,0,0.02,0c31.67,0,57.44,25.76,57.44,57.44c0,4.07,3.3,7.37,7.37,7.37c4.07,0,7.37-3.3,7.37-7.37
                            C165.26,158.49,132.88,126.11,93.09,126.1z" fill="#bdbdbd"></path><path class="st6" d="M54.31,137.39c-20.93,13.35-33.42,36.11-33.42,60.9c0,4.07,3.3,7.37,7.37,7.37c4.07,0,7.37-3.3,7.37-7.37
                            c0-19.73,9.94-37.85,26.6-48.47c3.43-2.18,4.44-6.74,2.25-10.18C62.3,136.21,57.74,135.2,54.31,137.39z" fill="#37474f"></path></svg>`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={Cookies.get('StaffID')}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                    >
                      {Cookies.get('Department').replace('Booking ', '')}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid> */}

      <Divider className={classes.divider} />
      <List>
        {menus.map((menu, index) => {
          const access = menu.access.find(
            (access) => access == Cookies.get('Department')
          )
          if (access)
            return (
              <Link className={classes.link} to={menu.link} key={index}>
                <ListItem button key={index}>
                  <Tooltip title={menu.title}>
                    <ListItemIcon className={classes.iconList}>
                      {menu.icon}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={menu.title} />
                </ListItem>
              </Link>
            )
        })}
      </List>
      <Divider className={classes.divider} />
      <List>
        <Link
          to={`/logout`}
          style={{ color: '#37474f', textDecoration: 'none' }}
        >
          <ListItem button key={1001}>
            <ListItemIcon className={classes.iconList}>
              <FontAwesomeIcon icon={faPowerOff} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Link>
      </List>
      <Divider className={classes.divider} />
    </div>
  )

  // we change the palette type of the theme in state
  const toggleDarkTheme = (isDarkTheme) => {
    if (isDarkTheme) {
      setTheme({
        transitions: { create: () => 'none' },
        palette: {
          primary: {
            main: '#263238',
            paper: '#323232',
            contrastText: '#000',
          },
          secondary: {
            main: '#263238',
          },
          background: {
            default: '#212121',
          },
          type: 'dark', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
          fontSize: 12,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    } else {
      setTheme({
        transitions: { create: () => 'none' },
        palette: {
          primary: {
            dark: '#263238',
            main: '#333',
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: '#333',
          },
          background: {
            default: '#efefef',
          },
          type: 'light', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
          fontSize: 12,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    }
  }

  const muiTheme = createMuiTheme(theme)

  const handleSignedIn = (link) => {
    setSignedIn(true)
    setRedirect(true)
    setRedirectTo(link)
  }

  const renderRedirect = () => {
    if (redirect) {
      setRedirect(false)
      return <Redirect to={redirectTo} />
    }
    if (redirectTo) return <Redirect to={redirectTo} />
  }

  useEffect(() => {
    if (Cookies.get('signedin')) {
      setSignedIn(true)
      axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get(
        'token'
      )}`
      client.writeData({
        data: {
          isDarkModeEnabled,
          loginDetails: {
            StaffID: Cookies.get('StaffID'),
            Department: Cookies.get('Department'),
          },
        },
      })

      console.log('staff', Cookies.get('StaffID'))
    }
  }, [])

  const menus = [
    /* { title: 'Home', link: '/', icon: <HomeIcon /> }, */
    {
      title: 'Manage',
      link: '/manage',
      icon: <FontAwesomeIcon icon={faFish} classes={classes.icon} />,
      access: ['Management', 'Farmadmin'],
    },
    {
      title: 'Sales',
      link: '/sales',
      icon: <FontAwesomeIcon icon={faDollarSign} classes={classes.icon} />,
      access: ['Management', 'Farmadmin'],
    },
    {
      title: 'Report',
      link: '/livereport',
      icon: <FontAwesomeIcon icon={faChartLine} classes={classes.icon} />,
      access: ['Management', 'Farmadmin'],
    },
    {
      title: 'Settings',
      link: '/settings',
      icon: <FontAwesomeIcon icon={faCog} classes={classes.icon} />,
      access: ['Management'],
    },
  ]

  return (
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider>
        <BrowserRouter>
          {renderRedirect()}

          <div className={classes.root}>
            <CssBaseline />
            {signedIn && (
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar variant="dense" style={{ paddingLeft: 20 }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    noWrap
                  ></Typography>

                  {/* <FormControlLabel
                control={<SwitchMUI checked={dense} onChange={handleToggle} />}
                label="test"
              /> */}
                  {/* {!['Pickup'].find(
                    (id) => id == Cookies.get('Department')
                  ) && <AppSearch redirect={handleSignedIn} />} */}
                </Toolbar>
              </AppBar>
            )}
            {signedIn && (
              <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                  <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                      }),
                    }}
                  >
                    {sideList('left')}
                  </Drawer>
                  <Divider className={classes.divider} />
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Divider />
                  <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                      }),
                    }}
                  >
                    {sideList('left')}
                  </Drawer>
                  <Divider className={classes.divider} />
                </Hidden>
              </nav>
            )}
            <main className={signedIn ? classes.content : null}>
              <div className={signedIn ? classes.toolbar : null}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(a) => <Login {...a} signedIn={setSignedIn} />}
                  />
                  <Route
                    path="/logout"
                    render={(a) => <Logout signedIn={setSignedIn} />}
                  />
                  <Route path="/batch" render={(a) => <BatchMain />} />
                  <Route path="/manage" render={(a) => <ManageMain />} />
                  <Route path="/farm" render={(a) => <FarmMain />} />
                  <Route path="/net" render={(a) => <NetMain />} />
                  <Route path="/viewdata" render={(a) => <ViewDataMain />} />
                  <Route path="/feed" render={(a) => <FeedMain />} />
                  <Route path="/staff" render={(a) => <StaffMain />} />
                  <Route path="/settings" render={(a) => <SettingsMain />} />
                  <Route path="/livereport" render={(a) => <Report />} />
                  <Route path="/sales" render={(a) => <SalesMain />} />
                  <Route
                    path="/viewdatabatch"
                    render={(a) => <ViewDataBatchMain />}
                  />

                  <Route
                    path="/basicsettings"
                    render={(a) => <BasicSettings />}
                  />
                  <Route
                    path="/login"
                    render={(a) => <Login {...a} signedIn={setSignedIn} />}
                  />
                  <Route
                    path="/logout"
                    render={(a) => <Logout signedIn={setSignedIn} />}
                  />
                  {/* <Route
                  path="/admin"
                  render={(a) => <Admin {...a} setTheme={setTheme} />}
                ></Route> */}
                </Switch>
              </div>
            </main>
          </div>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
