import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
//import CurrencyFormat from 'react-currency-format'

import TextField from '@material-ui/core/TextField'
//import useForm from 'react-hook-form'
//import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
//import Cookies from 'js-cookie'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Cookies from 'js-cookie'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { TextareaAutosize } from '@material-ui/core'

const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  table: {
    maxHeight: 500,
    backgroundColor: '#ddd',
    color: theme.palette.common.white,
  },
  tablecell: {
    backgroundColor: '#b1b1b1',
    whiteSpace: 'nowrap',
    color: theme.palette.common.white,
    padding: '5px 5px!important',
    width: 80,
    textAlign: 'center',
    border: '1px solid #f5f5f5',
    fontSize: 10,
  },
  tablebutton: {
    padding: 5,
    minWidth: 0,
    fontSize: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialog: {
    WebkitOverflowScrolling: 'touch',
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    paddingLeft: theme.spacing(1),
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2), paddingTop: 0 },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default function NetDialogTable(props) {
  const classes = useStyles()

  const [tabledata, setTabledata] = useState([])

  const [selectedNet, setSelectedNet] = useState([])

  useEffect(() => {
    if (props.farmData && props.netData.length > 0) {
      const farmSetup = props.farmData
      const netsDB = props.netData

      for (let a = 1; a <= farmSetup.TotalRows; a++) {
        tabledata[a] = { colA: [], colB: [] }

        for (let b = 1; b <= farmSetup.ColsA; b++) {
          const netvalue = netsDB.find((z) => z.NetCode == `A${a}-${b}`)

          let color = 'transparent'
          if (netvalue) {
            tabledata[a].colA.push({
              NetCode: `A${a}-${b}`,
              color: props.mainnet == `A${a}-${b}` ? 'red' : color,
            })
          } else {
            tabledata[a].colA.push({ NetCode: `A${a}-${b}` })
          }
        }

        for (let b = 1; b <= farmSetup.ColsB; b++) {
          const netvalue = netsDB.find((z) => z.NetCode == `B${a}-${b}`)

          let color = 'transparent'
          if (netvalue) {
            tabledata[a].colB.push({
              NetCode: `B${a}-${b}`,
              color: props.mainnet == `B${a}-${b}` ? 'red' : color,
            })
          } else {
            tabledata[a].colB.push({ NetCode: `B${a}-${b}` })
          }
        }

        //console.log('bside', tabledata[a].colB)
      }
    }
  }, [props])

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="farm-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="farm-dialog">
            {Cookies.get('FarmCode')}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            classes={{ root: classes.rootContainer }}
            className={classes.dialogContent}
          >
            {/* <pre>{JSON.stringify(tabledata, 0, 4)}</pre>
              <pre>{JSON.stringify(tablesetup, 0, 4)}</pre> */}
            <Grid container classes={{ root: classes.rootContainer }}>
              <Grid item xs={12} md={12}>
                <h3 style={{ marginBottom: 10 }}>A side</h3>
                <TableContainer component={Paper} style={{ height: 500 }}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {tabledata.length > 0 &&
                        tabledata.map((a, i) => (
                          <TableRow key={i}>
                            {a.colA &&
                              a.colA.map((b, j) => (
                                <React.Fragment key={j}>
                                  {b.color && (
                                    <TableCell
                                      classes={{ root: classes.tablecell2 }}
                                      style={{
                                        whiteSpace: 'nowrap',
                                        padding: '5px 5px!important',
                                        backgroundColor: b.color
                                          ? b.color
                                          : 'black',
                                        color: 'green',
                                        cursor: 'pointer',
                                      }}
                                      align="center"
                                      onClick={() => {
                                        props.setOpen(false)
                                        props.setMainnet(b.NetCode)
                                      }}
                                    >
                                      {b.NetCode}
                                    </TableCell>
                                  )}
                                  {!b.color && (
                                    <TableCell
                                      classes={{ root: classes.tablecell }}
                                      align="center"
                                      style={{
                                        backgroundColor:
                                          props.mainnet == b.NetCode
                                            ? 'red'
                                            : '#515151',
                                      }}
                                    >
                                      {b.NetCode}
                                    </TableCell>
                                  )}
                                </React.Fragment>
                              ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <h3 style={{ marginBottom: 10 }}>B side</h3>
                <TableContainer component={Paper} style={{ height: 500 }}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {tabledata.length > 0 &&
                        tabledata.map((a, i) => (
                          <TableRow key={i}>
                            {a.colB &&
                              a.colB.map((b, j) => (
                                <React.Fragment key={j}>
                                  {b.color && (
                                    <TableCell
                                      classes={{ root: classes.tablecell2 }}
                                      style={{
                                        whiteSpace: 'nowrap',
                                        padding: '5px 5px!important',
                                        backgroundColor: b.color
                                          ? b.color
                                          : 'black',
                                        color: 'green',
                                        cursor: 'pointer',
                                      }}
                                      align="center"
                                      onClick={() => {
                                        props.setOpen(false)
                                        props.setMainnet(b.NetCode)
                                      }}
                                    >
                                      {b.NetCode}
                                    </TableCell>
                                  )}
                                  {!b.color && (
                                    <TableCell
                                      classes={{ root: classes.tablecell }}
                                      align="center"
                                      style={{
                                        backgroundColor:
                                          props.mainnet == b.NetCode
                                            ? 'red'
                                            : '#515151',
                                      }}
                                    >
                                      {b.NetCode}
                                    </TableCell>
                                  )}
                                </React.Fragment>
                              ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <React.Fragment></React.Fragment>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
