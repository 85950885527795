import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'

import {
  UPDATE_STOCK,
  CREATE_STOCK,
  GET_STOCKS,
  GET_STOCKTAKES,
  CREATE_STOCKTAKE,
} from '../graphql/stock'
import { GET_PRODUCTS } from '../graphql/product'
import { GET_FEEDS } from '../graphql/feed'

import ConfirmationDialog from '../common/confirmationDialog'
import TextField from '../common/textField'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import TableHalf from '../common/tableHalf'

import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import AutocompleteField from '../common/autocompleteField'

const restApi = 'https://frapp.cloud:3670'

const dropdown = [
  { id: 'NEW_STOCK', title: 'New stock' },
  { id: 'STOCK_ADJUSTMENT', title: 'Stock take adjustment' },
  { id: 'OTHER', title: 'Other' },
  { id: 'RETURNED_STOCK', title: 'Returned stock' },
  /* { id: 'TRANSFERRED_IN', title: 'Transferred in' }, */
]
const locations = [{ id: 'SF_PUCHONG', title: 'SF Puchong' }]

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootBootstrap: {
    marginTop: 8,
    fontWeight: 'bold!important',
    'label + &': {
      fontWeight: 'bold!important',
      marginTop: theme.spacing(3),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px',
    paddingBottom: '10px!important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'FishfeedCode',
    label: 'Feed Code',
  },
  {
    id: 'Quantity',
    label: 'KG',
    textbox: true,
    width: 100,
  },
]

export default function ProductDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [stock, setStock] = useState()

  const [confirm, setConfirm] = useState(false)
  const [product, setProduct] = useState()
  const [productName, setProductName] = useState()
  const [reason, setReason] = useState()
  const [reasonName, setReasonName] = useState()
  const [stockItems, setStockItems] = useState([])
  const [location, setlocation] = useState()
  const [transactionType, setTransactionType] = useState('ADD')

  const { data: { products } = { products: [] } } = useQuery(GET_PRODUCTS)

  const {
    loading: loadingFarms,
    data: { feeds } = { feeds: [] },
    refetch: refetchFeeds,
    error,
  } = useQuery(GET_FEEDS)

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_STOCKTAKES,
      variables: { FarmCode: props && props.farm },
    })
    const latest = data.createstocktake
    /*  if (latest.Error) {
      enqueueSnackbar(latest.Error, {
        variant: 'error',
      })
      return
    } */
    cache.writeQuery({
      query: GET_STOCKTAKES,
      variables: { FarmCode: props && props.farm },
      data: { stocktakes: [latest, ...old.stocktakes] },
    })
    props.setOpen(false)
    props.setSelected([])
    props.setStock()
  }

  const [createstocktake] = useMutation(CREATE_STOCKTAKE, { update: addCache })

  const onSubmit = (values) => {
    if (stockItems.length < 1) {
      alert('Please add product')
      return
    }

    const output = stockItems.map((a) => {
      return {
        id: a.FishfeedCode,
        Title: a.FishfeedDesc,
        Qty: a.Quantity,
      }
    })

    const locationValue = locations.find((a) => a.title == location)

    createstocktake({
      variables: {
        TransactionType: transactionType,
        FarmCode: props && props.farm,
        Stocks: output,
      },
    })
  }

  const handleClickAdd = () => {
    if (!productName) return

    const productValue = feeds.find(
      (item) => `${item.FishfeedCode} - ${item.FishfeedDesc}` === productName
    )

    productValue.Quantity = 1

    if (stockItems.find((a) => a.FishfeedCode == productName)) return

    setStockItems([...stockItems, productValue])
    setProductName()
  }

  const handleUpdateTextbox = (value, row) => {
    if (!stockItems.find((a) => a.id == row.id)) return

    stockItems.map((a) => {
      if (a.id == row.id) {
        a.Quantity = value
      }
    })

    setStockItems([...stockItems])
  }

  const handleClickDelete = () => {
    setStockItems([...stockItems.filter((a) => a.id != stock.id)])
    setConfirm(false)

    setSelected([])
    setStock()
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to remove"
        okButton="Yes"
        title="Are you sure?"
      />
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              New Stock - {props && props.farm}
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <ButtonGroup
                      style={{ marginTop: 35 }}
                      variant="contained"
                      color="primary"
                    >
                      <Button
                        color={
                          transactionType == 'ADD' ? 'primary' : 'secondary'
                        }
                        onClick={() => {
                          setTransactionType('ADD')
                        }}
                      >
                        Add Stock
                      </Button>
                      <Button
                        color={
                          transactionType == 'REDUCE' ? 'primary' : 'secondary'
                        }
                        onClick={() => {
                          setTransactionType('REDUCE')
                        }}
                      >
                        Reduce Stock
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <AutocompleteField
                      name="Reason"
                      label="Transaction Type"
                      value={reasonName}
                      options={dropdown.map((item) => `${item.title}`)}
                      onChange={(e, value) => {
                        const reasonValue = dropdown.find(
                          (item) => `${item.title}` === value
                        )
                        if (reasonValue) {
                          setReason(reasonValue.id)
                          setReasonName(`${reasonValue.title}`)
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Divider className={classes.dividerroot} />
                <Grid container spacing={3}>
                  <Grid item md={8} xs={6}>
                    <AutocompleteField
                      name="FeedCode"
                      label="Select Feed"
                      value={productName}
                      options={feeds.map(
                        (item) => `${item.FishfeedCode} - ${item.FishfeedDesc}`
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setProductName(value)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Button
                      style={{ marginTop: 35 }}
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => {
                        handleClickAdd()
                      }}
                    >
                      Add Product
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ marginTop: 40 }}>
                  {stockItems && stockItems.length > 0 && (
                    <TableHalf
                      updateTextbox={handleUpdateTextbox}
                      disableSelected={false}
                      clickOnSelect={true}
                      hidePagination={true}
                      hideChange={true}
                      hideDelete={false}
                      hideSearch={true}
                      setConfirm={setConfirm}
                      selected={selected}
                      setSelected={setSelected}
                      tableState={setStock}
                      tableData={stockItems}
                      setOpen={setOpen}
                      tableHead={tableHead}
                    ></TableHalf>
                  )}
                </div>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                {/* <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton> */}
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </DialogActions>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
