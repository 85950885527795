import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { useMutation, useQuery } from '@apollo/react-hooks'
import ConfirmationDialog from '../common/confirmationDialog'
import Cookies from 'js-cookie'
import {
  GET_NETS,
  GET_MAINNET_SELECT,
  UPDATE_NET,
  CREATE_NET,
  SET_HARVEST_NET,
} from '../graphql/net'

import {
  GET_NETBATCHES,
  GET_BATCH_STOCKS,
  REMOVE_BATCH_STOCK,
} from '../graphql/batch'

import TextField from '@material-ui/core/TextField'
import useForm from 'react-hook-form'
import TableHalf from '../common/tableHalf'
import InputAdornment from '@material-ui/core/InputAdornment'

import NetBatchDialog from './netbatchDialog'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import TextFieldDisplay from '../common/textFieldDisplay'
import NetDialogTable from './netDialogTable'
import NetBatchStockDialog from './netbatchStockDialog'
import Loading from '../common/loading'

const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.common.white,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.common.white,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'BatchCode',
    label: 'Batch',
    width: 100,
  },
  {
    id: 'SpeciesName',
    label: 'Species',
  },
  {
    id: 'Pcs',
    label: 'Current Pcs',
    weight: true,
    numeric: true,
    width: 120,
  },
  {
    id: 'CreatedBy',
    logdate: 'CreatedOn',
    width: 150,
    log: true,
    disablePadding: true,
    label: 'Last Updated',
  },
]

const tableHeadStock = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'StockTakeDate',
    label: 'Stock Date',
    date: true,
  },
  {
    id: 'TransactionType',
    label: 'Action',
  } /* ,
  {
    id: 'Reason',
    label: 'Type',
  } */,
  {
    id: 'ReasonType',
    label: 'Type',
  },
  {
    id: 'SalesType',
    label: 'Sales Type',
  },
  {
    id: 'Qty',
    label: 'Pcs',
    weight: true,
    numeric: true,
  },
  {
    id: 'AvgSize',
    label: 'Avg Size (Kg)',
    weight: true,
    numeric: true,
  },
  {
    id: 'Remarks',
    label: 'Remarks',
    width: 300,
  },
  {
    width: 150,
    id: 'CreatedBy',
    logdate: 'CreatedOn',
    log: true,
    disablePadding: true,
    label: 'Last Updated',
  },
  ,
]

export default function ProductDialog(props) {
  const classes = useStyles()
  const [selectedStock, setSelectedStock] = useState([])
  const [selected, setSelected] = useState([])
  const [openNetBatch, setOpenNetBatch] = useState(false)
  const [open, setOpen] = useState(false)
  const [openStock, setOpenStock] = useState(false)
  const [openTable, setOpenTable] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [batch, setBatch] = useState()
  const [stock, setStock] = useState()

  const { handleSubmit, register, errors, setValue } = useForm()
  const [mainnet, setMainnet] = useState()

  const { refetch: refetchNets } = useQuery(GET_NETS, {
    variables: {
      FarmCode: props.farm,
    },
    skip: !props.farm,
  })

  const {
    data: { mainnetselect } = { mainnetselect: [] },
    refetch: refetchSelect,
  } = useQuery(GET_MAINNET_SELECT, {
    variables: {
      FarmCode: props.farm,
    },
  })

  const {
    data: { batchstocks } = { batchstocks: [] },
    refetch: refetchBatchStocks,
    loading: loadingBatchStocks,
  } = useQuery(GET_BATCH_STOCKS, {
    variables: {
      BatchID: batch && batch['id'],
      FarmCode: batch && batch['FarmCode'],
      NetCode: batch && batch['NetCode'],
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: { netbatches } = { netbatches: [] },
    refetch: refetchNetbatches,
  } = useQuery(GET_NETBATCHES, {
    variables: {
      NetCode: props.data && props.data.NetCode,
      FarmCode: props.data && props.farm,
      ShowHarvested: false,
    },
    fetchPolicy: 'network-only',
  })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_NETS,
      variables: {
        FarmCode: props.farm,
      },
    })
    const latest = data.insertnet
    cache.writeQuery({
      query: GET_NETS,
      variables: {
        FarmCode: props.farm,
      },
      data: { nets: [latest, ...old.nets] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setNet()
    refetchSelect({
      FarmCode: props.farm,
    })
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_NETS,
      variables: {
        FarmCode: props.farm,
      },
    })
    const latest = data.updatenet
    const foundIndex = old.nets.findIndex((item) => item.id === latest.id)
    old.nets.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_NETS,
      variables: {
        FarmCode: props.farm,
      },
      data: { nets: [...old.nets] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setNet()

    refetchSelect({
      FarmCode: props.farm,
    })
  }

  const setHarvestCache = (cache, { data }) => {
    refetchNetbatches()
    props.setSelected([])
    props.setBatch(undefined)
  }

  /* const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRODUCTS,
    })
    if (data.removeproduct) {
      const latest = old.products.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: { products: [...latest] },
      })
      props.setSelected([])
      props.setProduct()
    }
  } */

  const [setharvestnet] = useMutation(SET_HARVEST_NET, {
    update: setHarvestCache,
  })
  const [inserNet] = useMutation(CREATE_NET, { update: addCache })
  const [updateNet] = useMutation(UPDATE_NET, {
    update: updateCache,
  })
  /*  const [removeProduct] = useMutation(REMOVE_PRODUCT, {
    update: deleteCache,
  }) */

  const onSubmit = (values) => {
    /* FarmCode: $FarmCode
      FarmID: $FarmID
      SubFarmID: $SubFarmID
      NetCode: $NetCode
      NetName: $NetName
      NetDesc: $NetDesc
      Remarks: $Remarks
      NetRows: $NetRows
      NetCols: $NetCols
      NetColor: $NetColor
      MainNetID: $MainNetID
      MainNetCode: $MainNetCode
      Prefix: $Prefix
      Row: $Row
      Col: $Col
      Batch: $Batch
    Pcs: $Pcs */

    if (props.data && props.data.id) {
      updateNet({
        variables: {
          id: props.data.id,
          FarmCode: props.farm,
          NetCode: mainnet,
          /*  NetName: values.NetName,
          NetDesc: values.NetDesc,
          NetRows: values.NetRows && parseInt(values.NetRows),
          NetCols: values.NetCols && parseInt(values.NetCols), */
        },
      })
    } else {
      inserNet({
        variables: {
          FarmCode: props.farm,
          NetCode: mainnet,
          /*  NetName: values.NetName,
          NetDesc: values.NetDesc,
          NetRows: values.NetRows && parseInt(values.NetRows),
          NetCols: values.NetCols && parseInt(values.NetCols), */
        },
      })
    }
  }

  const deleteCache = (cache, { data }) => {
    setSelectedStock([])
    refetchBatchStocks()
    refetchNetbatches()
  }

  const [removeBatchStock] = useMutation(REMOVE_BATCH_STOCK, {
    update: deleteCache,
  })

  const handleClickDelete = () => {
    removeBatchStock({
      variables: {
        id: stock && stock['id'],
      },
    })
    setConfirm(false)
    setSelectedStock([])
    setStock()
  }

  useEffect(() => {
    setBatch(null)
    setSelected([])
    setSelectedStock([])
    refetchNetbatches()
    if (!props.data) {
      setMainnet(null)
      return
    }
    if (props.data.MainNetCode) setMainnet(props.data.MainNetCode)
  }, [props])

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to remove stock"
        okButton="Yes"
        title="Are you sure?"
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
          refetchNets()
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="farm-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="farm-dialog">
            {props.data && `Manage Net`}
            {!props.data && 'New Net'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
                refetchNets()
                setBatch()
                setStock()
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={3}>
              <Grid item md={2} xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <TextFieldDisplay label="Farm Code" value={props.farm} />
                    </Grid>
                  </Grid>

                  <Grid container classes={{ root: classes.rootContainer }}>
                    <Grid item xs={12} md={12}>
                      {props.data && (
                        <TextFieldDisplay
                          label="Net"
                          value={props.data.NetCode}
                        />
                      )}
                      {!props.data && (
                        <TextField
                          name="SearchText"
                          value={mainnet}
                          label="Select Net"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  color="primary"
                                  aria-label="directions"
                                  onClick={() => {
                                    setOpenTable(true)
                                  }}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {/* <Grid container>
                    <Grid item md={12} xs={12}>
                      <Button margin="dense" variant="contained" type="submit">
                        Update net
                      </Button>
                    </Grid>
                  </Grid> */}

                  {!props.data && (
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <Button
                          margin="dense"
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Save new
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </form>
              </Grid>

              <NetBatchDialog
                setOpen={setOpen}
                setSelected={setSelected}
                setBatch={setBatch}
                data={batch}
                open={open}
                nets={props.nets}
                farm={props.farm}
                net={props.data}
              />
              <NetBatchStockDialog
                refetchNetBatches={refetchNetbatches}
                refetch={refetchBatchStocks}
                setOpen={setOpenStock}
                open={openStock}
                stock={stock}
                onSuccess={() => {
                  setStock()
                  setSelectedStock([])
                }}
                farmCode={batch && batch['FarmCode']}
                batchCode={batch && batch['BatchCode']}
                batchID={batch && batch['id']}
                netCode={batch && batch['NetCode']}
              ></NetBatchStockDialog>

              <NetDialogTable
                setOpen={setOpenTable}
                farmData={props.farmData}
                netData={mainnetselect}
                open={openTable}
                mainnet={mainnet}
                setMainnet={setMainnet}
              />

              {props.data && (
                <Grid item md={10} xs={12}>
                  <Button
                    margin="dense"
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                      setSelected([])
                      setBatch()
                      setOpen(true)
                    }}
                  >
                    Add Batch
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: 5 }}
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                      refetchNetbatches({
                        NetCode: props.data && props.data.NetCode,
                        FarmCode: props.data && props.farm,
                        ShowHarvested: false,
                      })
                      if (batch) refetchBatchStocks()
                    }}
                  >
                    Excl. Harvested
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: 5 }}
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                      refetchNetbatches({
                        NetCode: props.data && props.data.NetCode,
                        FarmCode: props.data && props.farm,
                        ShowHarvested: true,
                      })
                      setSelected([])
                      setBatch()
                    }}
                  >
                    Show Harvested
                  </Button>
                  {batch && (
                    <div>
                      {batch.Harvested !== 1 && (
                        <Button
                          variant="outlined"
                          style={{ marginLeft: 0, marginTop: 5 }}
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setOpen(true)
                          }}
                        >
                          Move/Remove
                        </Button>
                      )}
                      {batch.Harvested !== 1 && (
                        <Button
                          variant="outlined"
                          style={{ marginLeft: 5, marginTop: 5 }}
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setharvestnet({
                              variables: {
                                id: batch.id,
                                FarmCode: batch.FarmCode,
                                NetCode: batch.NetCode,
                                BatchCode: batch.BatchCode,
                                Harvested: true,
                              },
                            })
                          }}
                        >
                          Set Harvested
                        </Button>
                      )}
                      {batch.Harvested === 1 && (
                        <Button
                          variant="outlined"
                          style={{ marginLeft: 0, marginTop: 5 }}
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setharvestnet({
                              variables: {
                                id: batch.id,
                                FarmCode: batch.FarmCode,
                                NetCode: batch.NetCode,
                                BatchCode: batch.BatchCode,
                                Harvested: false,
                              },
                            })
                          }}
                        >
                          Undo Harvested
                        </Button>
                      )}
                    </div>
                  )}

                  <div style={{ marginTop: 5 }}>
                    <TableHalf
                      clickOnSelect={false}
                      hideSearch={true}
                      hidePagination={true}
                      hideChange={true}
                      hideDelete={true}
                      selected={selected}
                      setSelected={setSelected}
                      tableState={setBatch}
                      tableData={netbatches}
                      setOpen={setOpen}
                      tableHead={tableHead}
                    ></TableHalf>
                  </div>
                </Grid>
              )}
            </Grid>
            <br />
            {batch && !loadingBatchStocks && (
              <>
                <h2>Current Stock ({batch && batch['BatchCode']})</h2>
                {batch.Harvested !== 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setStock(() => undefined)
                      setOpenStock(() => true)
                      setSelectedStock([])
                    }}
                  >
                    Update Stock
                  </Button>
                )}
                <Button
                  variant="outlined"
                  style={{ marginLeft: batch.Harvested !== 1 ? 5 : 0 }}
                  startIcon={<RefreshIcon />}
                  onClick={() => {
                    refetchNetbatches()
                    if (batch) refetchBatchStocks()
                  }}
                >
                  Refresh
                </Button>
                <br />
                {batch.Harvested !== 1 && stock && (
                  <div>
                    <Button
                      variant="outlined"
                      style={{ marginLeft: 0, marginTop: 5 }}
                      startIcon={<EditIcon />}
                      onClick={() => {
                        setOpenStock(true)
                      }}
                    >
                      Edit Stock
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ marginLeft: 5, marginTop: 5 }}
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setConfirm(true)
                      }}
                    >
                      Remove Stock
                    </Button>
                  </div>
                )}
                <div style={{ marginTop: 5 }}>
                  <TableHalf
                    rowsPerPage={20}
                    clickOnSelect={false}
                    hideSearch={true}
                    setOpen={setOpen}
                    hidePagination={false}
                    hideChange={true}
                    hideDelete={true}
                    setConfirm={setConfirm}
                    selected={selectedStock}
                    setSelected={setSelectedStock}
                    tableState={setStock}
                    tableData={batchstocks}
                    tableHead={tableHeadStock}
                  ></TableHalf>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <React.Fragment>
              {/* <IconButton
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setConfirm(true)
                    }}
                    edge="end"
                    aria-label="comments"
                  >
                    <DeleteIcon />
                  </IconButton> */}
            </React.Fragment>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
