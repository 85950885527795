import { gql } from 'apollo-boost'

export const GET_CLOSINGHEADERS = gql`
  query ClosingHeaders($FarmCode: ID) {
    closingheaders(FarmCode: $FarmCode) {
      id
      ClosingDate
      ClosingDateStart
      ClosingType
      StockOutKG
      StockOutBags
      StockInKG
      StockInBags
      ClosingBalanceKG
      ClosingBalanceBags
      OpeningBalanceKG
      OpeningBalanceBags
      FarmCode
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
      Error
    }
  }
`

export const GET_CLOSINGDETAILS = gql`
  query ClosingDetails($ClosingID: ID!) {
    closingdetails(ClosingID: $ClosingID) {
      id
      ClosingID
      ItemID
      FarmCode
      StockOutKG
      StockOutBags
      StockInKG
      StockInBags
      ClosingBalanceKG
      ClosingBalanceBags
      OpeningBalanceKG
      OpeningBalanceBags
      CreatedOn
      CreatedBy
    }
  }
`

export const INSERT_CLOSINGHEADER = gql`
  mutation InsertClosingHeader($ClosingDate: Date, $FarmCode: ID) {
    insertclosingheader(ClosingDate: $ClosingDate, FarmCode: $FarmCode) {
      id
      ClosingDate
      ClosingDateStart
      ClosingType
      StockOutKG
      StockOutBags
      StockInKG
      StockInBags
      ClosingBalanceKG
      ClosingBalanceBags
      OpeningBalanceKG
      OpeningBalanceBags
      FarmCode
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
      Error
    }
  }
`

export const REMOVE_CLOSINGHEADER = gql`
  mutation RemoveClosingHeader($id: ID!) {
    removeclosingheader(id: $id) {
      id
      Error
    }
  }
`
