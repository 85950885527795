import { gql } from 'apollo-boost'

export const GET_BATCHES = gql`
  {
    batches {
      id
      BatchCode
      BatchDesc
      Pcs
      SpeciesCode
      SpeciesName
    }
  }
`

export const GET_NETBATCHES = gql`
  query NetBatches(
    $NetCode: String
    $FarmCode: String
    $ShowHarvested: Boolean
  ) {
    netbatches(
      NetCode: $NetCode
      FarmCode: $FarmCode
      ShowHarvested: $ShowHarvested
    ) {
      id
      FarmID
      FarmCode
      NetID
      NetCode
      BatchID
      BatchCode
      Pcs
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      SpeciesCode
      SpeciesName
      Harvested
    }
  }
`

export const CREATE_BATCH = gql`
  mutation CreateBatch($BatchCode: String, $SpeciesCode: String) {
    insertbatch(BatchCode: $BatchCode, SpeciesCode: $SpeciesCode) {
      id
      BatchCode
      BatchDesc
      Pcs
      SpeciesCode
      SpeciesName
      Harvested
    }
  }
`

export const UPDATE_BATCH = gql`
  mutation UpdateBatch($id: ID, $BatchCode: String, $SpeciesCode: String) {
    updatebatch(id: $id, BatchCode: $BatchCode, SpeciesCode: $SpeciesCode) {
      id
      BatchCode
      BatchDesc
      Pcs
      SpeciesCode
      SpeciesName
      Harvested
    }
  }
`

export const REMOVE_BATCH = gql`
  mutation RemoveBatch($id: ID!) {
    removebatch(id: $id)
  }
`

export const CREATE_NETBATCH = gql`
  mutation CreateNetBatch(
    $FarmCode: String
    $NetCode: String
    $BatchID: ID
    $BatchCode: String
    $Pcs: Int
  ) {
    insertnetbatch(
      FarmCode: $FarmCode
      NetCode: $NetCode
      BatchCode: $BatchCode
      BatchID: $BatchID
      Pcs: $Pcs
    ) {
      id
      FarmID
      FarmCode
      NetID
      NetCode
      BatchID
      BatchCode
      Pcs
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      SpeciesCode
      SpeciesName
      Harvested
    }
  }
`

export const UPDATE_NETBATCH = gql`
  mutation UpdateNetBatch(
    $id: ID
    $FarmCode: String
    $NetCode: String
    $BatchID: ID
    $BatchCode: String
    $Pcs: Int
  ) {
    updatenetbatch(
      id: $id
      FarmCode: $FarmCode
      NetCode: $NetCode
      BatchID: $BatchID
      BatchCode: $BatchCode
      Pcs: $Pcs
    ) {
      id
      FarmID
      FarmCode
      NetID
      NetCode
      BatchID
      BatchCode
      Pcs
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      SpeciesCode
      SpeciesName
      Harvested
    }
  }
`
export const REMOVE_NETBATCH = gql`
  mutation RemoveNetBatch($id: ID!) {
    removenetbatch(id: $id) {
      id
      Error
    }
  }
`
export const GET_BATCH_STOCKS = gql`
  query BatchStocks($BatchID: ID!, $FarmCode: String!, $NetCode: String!) {
    batchstocks(BatchID: $BatchID, FarmCode: $FarmCode, NetCode: $NetCode) {
      id
      StockTakeDate
      TransactionType
      BatchID
      BatchCode
      FarmCode
      NetCode
      Title
      Movement
      Qty
      Kg
      KgToQty
      AvgSize
      Reason
      ReasonType
      Remarks
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      Harvested
      SalesType
    }
  }
`

export const CREATE_BATCH_STOCK = gql`
  mutation CreateBatchStock(
    $FarmCode: String
    $NetCode: String
    $BatchID: ID
    $BatchCode: String
    $ReasonType: String
    $Qty: Int
    $TransactionType: String
    $Reason: String
    $Remarks: String
    $AvgSize: Float
    $StockTakeDate: Date
    $SalesType: String
  ) {
    createbatchstock(
      FarmCode: $FarmCode
      NetCode: $NetCode
      BatchCode: $BatchCode
      ReasonType: $ReasonType
      BatchID: $BatchID
      Qty: $Qty
      TransactionType: $TransactionType
      Reason: $Reason
      Remarks: $Remarks
      AvgSize: $AvgSize
      StockTakeDate: $StockTakeDate
      SalesType: $SalesType
    ) {
      id
      StockTakeDate
      TransactionType
      BatchID
      BatchCode
      FarmCode
      NetCode
      Title
      Movement
      Qty
      Kg
      KgToQty
      AvgSize
      Reason
      Remarks
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      SalesType
    }
  }
`

export const UPDATE_BATCH_STOCK = gql`
  mutation UpdateBatchStock(
    $id: ID!
    $FarmCode: String
    $NetCode: String
    $BatchID: ID
    $BatchCode: String
    $ReasonType: String
    $Qty: Int
    $TransactionType: String
    $Reason: String
    $Remarks: String
    $AvgSize: Float
    $StockTakeDate: Date
    $SalesType: String
  ) {
    updatebatchstock(
      id: $id
      FarmCode: $FarmCode
      NetCode: $NetCode
      BatchCode: $BatchCode
      ReasonType: $ReasonType
      BatchID: $BatchID
      Qty: $Qty
      TransactionType: $TransactionType
      Reason: $Reason
      Remarks: $Remarks
      AvgSize: $AvgSize
      StockTakeDate: $StockTakeDate
      SalesType: $SalesType
    ) {
      id
      StockTakeDate
      TransactionType
      BatchID
      BatchCode
      FarmCode
      NetCode
      Title
      Movement
      Qty
      Kg
      KgToQty
      AvgSize
      Reason
      Remarks
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      SalesType
    }
  }
`

export const REMOVE_BATCH_STOCK = gql`
  mutation RemoveBatchStock($id: ID!) {
    removebatchstock(id: $id)
  }
`

export const GET_BATCH_STOCK_CURRENT = gql`
  {
    batchstockcurrent {
      id
      FarmID
      FarmCode
      NetID
      NetCode
      BatchID
      BatchCode
      Pcs
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      SpeciesCode
      SpeciesName
      LastCountDate
      InPcs
      DeadPcs
      TotalFeed
      TotalFeedIBB
      TotalFeedIFF
      TotalFeedPallet
      AverageSize
      PurchaseSize
      TotalWeight
      NetQtyPcs
      YearOfPurchase
      TypesOfFish
      SalesPcs
      InvisibleDeadPcs
      SalesRm
      SalesKg
    }
  }
`

export const GET_BATCH_STOCK_CURRENT_HARVESTED = gql`
  {
    batchstockcurrentharvested {
      id
      FarmID
      FarmCode
      NetID
      NetCode
      BatchID
      BatchCode
      Pcs
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      SpeciesCode
      SpeciesName
      LastCountDate
      InPcs
      DeadPcs
      TotalFeed
      TotalFeedIBB
      TotalFeedIFF
      TotalFeedPallet
      AverageSize
      PurchaseSize
      TotalWeight
      NetQtyPcs
      YearOfPurchase
      TypesOfFish
      SalesPcs
      InvisibleDeadPcs
      SalesRm
      SalesKg
    }
  }
`

export const GET_BATCH_STOCK_PURCHASING = gql`
  query BatchStockPurchasing($FarmCode: String!) {
    batchstockpurchasing(FarmCode: $FarmCode) {
      id
      FarmID
      FarmCode
      NetID
      NetCode
      BatchID
      BatchCode
      Pcs
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      SpeciesCode
      SpeciesName
      LastCountDate
      InPcs
      DeadPcs
      TotalFeed
      TotalFeedIBB
      TotalFeedIFF
      TotalFeedPallet
      AverageSize
      PurchaseSize
      TotalWeight
      NetQtyPcs
      YearOfPurchase
      TypesOfFish
      SalesPcs
      SalesRm
      SalesKg
    }
  }
`
