import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import FarmDialog from './farmDialog'
import { GET_SALESFRYPURCHASING } from '../graphql/sales'
import SalesUploadDialog from './salesUploadDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import axios from 'axios'
import { useSnackbar } from 'notistack'

const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

/**'Month',
      'No',
      'Farm',
      'NewBatchNo',
      'OldBatchNo',
      'TypesOfFish',
      'PackingBoxXPcs',
      'TotalMortality',
      'NettQtyPcs',
      'UnitPrice',
      'AmountRm',
      'Size',
      'Suppliers',
      'Invoice', */
const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'PurchaseDate',
    label: 'PurchaseDate',
    date: true,
  },
  {
    id: 'Month',
    label: 'Month',
  },
  {
    id: 'No',
    label: 'No',
  },
  {
    id: 'Farm',
    label: 'Farm',
  },
  {
    id: 'NewBatchNo',
    label: 'NewBatchNo',
  },
  {
    id: 'OldBatchNo',
    label: 'OldBatchNo',
  },
  {
    id: 'TypesOfFish',
    label: 'TypesOfFish',
  },
  {
    id: 'PackingBoxXPcs',
    label: 'PackingBoxXPcs',
  },
  {
    id: 'TotalMortality',
    label: 'TotalMortality',
    weight: true,
  },
  {
    id: 'NettQtyPcs',
    label: 'NettQtyPcs',
    weight: true,
  },
  {
    id: 'UnitPrice',
    label: 'UnitPrice',
    currency: true,
  },
  {
    id: 'AmountRm',
    label: 'AmountRm',
    currency: true,
  },
  {
    id: 'Size',
    label: 'Size',
  },
  {
    id: 'Suppliers',
    label: 'Suppliers',
  },
  {
    id: 'Invoice',
    label: 'Invoice',
  },
]

export default function Sales(props) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [openUploadSales, setOpenUploadSales] = useState(false)
  const [sale, setSales] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [loadData, setLoadData] = useState(false)

  const {
    loading: loadingSales,
    data: { salesfrypurchasing } = { salesfrypurchasing: [] },
    refetch,
    error,
  } = useQuery(GET_SALESFRYPURCHASING, { skip: !loadData })

  const handleUploadSales = (file) => {
    if (!file) return
    setLoading(true)
    const formData = new FormData()
    //formData.append('uploads[]', file)
    //console.log(file)
    formData.append('uploads[]', file.file[0], 'temp.xlsx')
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    //formData.append("uploads[]", files[0], files[0]['name']);
    axios
      .put(restApi + `/upload/frypurchasing`, formData, config)
      .then((response) => {
        refetch()
        setLoading(false)
        enqueueSnackbar('The file is successfully uploaded', {
          variant: 'success',
        })
      })
      .catch((error) => {
        console.log(error)
      })
    setOpenUploadSales(false)
  }

  const handleClickOpen = () => {
    setSales()
    setSelected([])
    setOpen(true)
  }

  if (loadingSales || loading) return <Loading />

  if (error) return <p>API ERROR</p>

  return (
    <div className={classes.root}>
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure? Product?"
        okButton="Yes"
        title="Are you sure?"
      /> */}
      <SalesUploadDialog
        handleUpload={handleUploadSales}
        refetch={refetch}
        open={openUploadSales}
        setOpen={setOpenUploadSales}
      />
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        New
      </Button>
      <Button
        variant="outlined"
        color="primary"
        style={{ marginLeft: 5 }}
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetch()
          setLoadData(true)
        }}
      >
        Load Data
      </Button>

      <Button
        variant="outlined"
        color="primary"
        style={{ marginLeft: 5 }}
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          setOpenUploadSales(true)
        }}
      >
        Upload
      </Button>
      <h3 style={{ marginBottom: 0 }}>
        Total Records: {salesfrypurchasing?.length}
      </h3>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          {/* <FarmDialog
            setOpen={setOpen}
            setSelected={setSelected}
            setSales={setSales}
            data={farm}
            open={open}
            refetchFarms={refetch}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            hidePagination={false}
            rowsPerPage={20}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setSales}
            tableData={salesfrypurchasing}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
