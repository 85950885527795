import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import StockDialog from './stockDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import { GET_STOCKTAKES, CREATE_STOCKTAKE } from '../graphql/stock'
import { GET_PRODUCTS } from '../graphql/product'
import { GET_FARMS } from '../graphql/farm'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'
import StockDisplayDialog from './stockDisplayDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'StockTakeDate',
    label: 'Stock In Date',
    date: true,
  },
  {
    id: 'FarmCode',
    label: 'FarmCode',
  },
  {
    id: 'TransactionType',
    label: 'Transaction Type',
  },
  {
    id: 'Qty',
    label: 'Total KG',
  },
  {
    id: 'Bags',
    label: 'Total Bags',
  },
  {
    id: 'CreatedBy',
    logdate: 'CreatedOn',
    dateLapse: 'DateLapse',
    log: true,
    disablePadding: true,
    label: 'Created By',
    width: 300,
  },
]

export default function StockTake() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [openDisplay, setOpenDisplay] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [stock, setStock] = useState()
  const [stocktake, setStocktake] = useState()
  const [farm, setFarm] = useState()

  /*  const {
    data: { stocks } = { stocks: [] },
    refetch: refetchStocks,
  } = useQuery(GET_STOCKS, {
    variables: {
      Movement: 'In',
    },
  }) */

  const {
    loading: loadingFarms,
    data: { farms } = { farms: [] },
    refetch: refetchFarms,
  } = useQuery(GET_FARMS)

  const { data: { stocktakes } = { stocktakes: [] }, refetch: refetchStocks } =
    useQuery(GET_STOCKTAKES)

  const [createStocktake] = useMutation(CREATE_STOCKTAKE)

  const handleClickOpen = () => {
    setConfirm(true)
    setStock()
    setSelected([])
  }

  const handleClickNew = () => {
    setOpen(true)
  }

  /*
  //if (loading) return <Loading />
  if (error) return <p>API ERROR</p> 
  */

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        action={handleClickNew}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Create new Stock Take?"
        okButton="Yes"
        title="Close"
      />
      {!loadingFarms && (
        <Grid container classes={{ root: classes.rootContainer }}>
          <Grid item xs={6} md={2}>
            <FormControl /* error={errors.Feed && true} */ fullWidth>
              <InputLabel
                classes={{ root: classes.rootInputlabel }}
                shrink
                htmlFor="Farm"
              >
                Farm
              </InputLabel>
              <Select
                labelId="Farm"
                id="Farm"
                name="Farm"
                value={farm}
                onChange={(e) => {
                  setFarm(e.target.value)
                  refetchStocks({ FarmCode: e.target.value })
                }}
                input={
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                  />
                }
                /*  inputRef={register({
              required: 'This field is Required'
            })} */
              >
                {farms.map((a, i) => (
                  <MenuItem key={i} value={a.FarmCode}>
                    {a.FarmCode}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {/* {errors.Feed && errors.Feed.message} */}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}

      {farm && (
        <React.Fragment>
          <Button
            variant="contained"
            color="primary"
            color="primary"
            onClick={handleClickNew}
          >
            New
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 5 }}
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={() => {
              refetchStocks({ FarmCode: farm })
            }}
          >
            Refresh
          </Button>

          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <StockDialog
                setOpen={setOpen}
                setSelected={setSelected}
                setStock={setStock}
                data={stock}
                open={open}
                farm={farm}
                refetchStocks={refetchStocks}
              />
              <StockDisplayDialog
                setOpen={setOpenDisplay}
                setSelected={setSelected}
                setStock={setStock}
                data={stock}
                open={openDisplay}
                farm={farm}
                refetchStocks={refetchStocks}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TableHalf
                clickOnSelect={true}
                hidePagination={false}
                hideChange={true}
                hideDelete={true}
                setConfirm={setConfirm}
                selected={selected}
                setSelected={setSelected}
                tableState={setStock}
                tableData={stocktakes}
                setOpen={setOpenDisplay}
                tableHead={tableHead}
              ></TableHalf>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
          </Grid>
        </React.Fragment>
      )}
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
