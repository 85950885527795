import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import axios from 'axios'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'

// const contactFormEndpoint = process.env.REACT_APP_CONTACT_ENDPOINT
const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  dropZone: {
    background: theme.palette.primary.paper,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}))

// START
export default function ConfigUploadDialog(props) {
  const classes = useStyles()
  const [file, setFile] = useState()
  const [disable, setDisable] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (file) setDisable(false)
  }, [file])

  const handleUpload = (e) => {
    e.preventDefault()

    if (!file) return
    const formData = new FormData()
    //formData.append('uploads[]', file)
    console.log(file)
    formData.append('uploads[]', file.file[0], 'temp.jpg')
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    //formData.append("uploads[]", files[0], files[0]['name']);
    axios
      .put(restApi + `/upload/companylogo`, formData, config)
      .then((response) => {
        console.log(response)
        enqueueSnackbar('The file is successfully uploaded', {
          variant: 'success',
        })
        props.setOpen(false)
        props.refetch()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onChange = (e) => {
    console.log(e)
    setFile({ file: e })
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="manifesting-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="manifesting-dialog">
            Upload
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleUpload}>
            <DialogContent className={classes.dialogContent}>
              <DropzoneArea
                dropzoneClass={classes.dropZone}
                filesLimit={1}
                acceptedFiles={['image/*']}
                showPreviewsInDropzone={true}
                showPreviews={false}
                dropzoneText="Drag and drop a file here or click"
                onChange={onChange}
              />
              {/* <input type="file" name="myImage" onChange={onChange} /> */}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={disable}
              >
                Upload
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
