import { gql } from 'apollo-boost'

export const GET_FEEDCOSTINGS = gql`
  query FeedCostings($id: ID!) {
    feedcostings(id: $id) {
      id
      FishfeedID
      CostPerKg
      EffectiveDate
      ModifiedBy
      ModifiedOn
    }
  }
`

export const REMOVE_FEEDCOSTINGS = gql`
  mutation RemoveFeedCosting($id: ID!) {
    removefeedcosting(id: $id)
  }
`
export const UPDATE_FEEDCOSTINGS = gql`
  mutation UpdateFeedCosting(
    $id: ID
    $FishfeedID: ID
    $CostPerKg: Float
    $EffectiveDate: Date
  ) {
    updatefeedcosting(
      id: $id
      FishfeedID: $FishfeedID
      CostPerKg: $CostPerKg
      EffectiveDate: $EffectiveDate
    ) {
      id
      FishfeedID
      CostPerKg
      EffectiveDate
      ModifiedBy
      ModifiedOn
    }
  }
`

export const CREATE_FEEDCOSTINGS = gql`
  mutation CreateFeedCosting(
    $FishfeedID: ID
    $CostPerKg: Float
    $EffectiveDate: Date
  ) {
    insertfeedcosting(
      FishfeedID: $FishfeedID
      CostPerKg: $CostPerKg
      EffectiveDate: $EffectiveDate
    ) {
      id
      FishfeedID
      CostPerKg
      EffectiveDate
      ModifiedBy
      ModifiedOn
    }
  }
`
