import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DatepickerField from '../common/datepickerField'
import useForm from 'react-hook-form'
import axios from 'axios'
import TableHalf from '../common/tableHalf'
import { useSnackbar } from 'notistack'
import ConfirmationDialog from '../common/confirmationDialog'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import GetAppIcon from '@material-ui/icons/GetApp'

import { useQuery } from '@apollo/react-hooks'
import { GET_VIEWDATAS } from '../graphql/viewdata'

import { GET_FARMS } from '../graphql/farm'

import Loading from '../common/loading'
import DataEntry from './dataEntry'
import { useHotkeys } from 'react-hotkeys-hook'

const restApi = 'https://frapp.cloud/fishfarm'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 750,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: true,
    label: 'ID',
  },
  {
    id: 'Row',
    label: 'No',
  },
  {
    id: 'CreatedOn',
    date: true,
    label: 'Date',
  },
  {
    id: 'FarmCode',
    numeric: false,
    label: 'Farm',
  },
  {
    id: 'Batch',
    numeric: false,
    label: 'Batch',
  },
  {
    id: 'NetCode',
    numeric: false,
    label: 'Net',
  },
  {
    id: 'Pcs',
    numeric: true,
    label: 'Pcs',
  },
  {
    id: 'FishfeedCode',
    numeric: true,
    label: 'Feed Type',
  },
  {
    id: 'Qty',
    numeric: true,
    label: 'Feed Qty(Kg)',
  },
  {
    id: 'CostPerKg',
    numeric: true,
    label: 'Cost Per Kg',
  },
  {
    id: 'MortalityPcs',
    numeric: true,
    label: 'Mortality(Pcs)',
  },
  {
    id: 'MortalityKg',
    numeric: true,
    label: 'Mortality(Kg)',
  },
]

export default function ViewData(props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [from, setFrom] = useState(null)
  const [awb, setAwb] = useState()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [loadingPrint, setLoadingPrint] = useState(false)

  const [confirm, setConfirm] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [to, setTo] = useState(null)
  const [farm, setFarm] = useState()

  useHotkeys('f2', () => {
    setOpen(true)
  })

  const {
    loading: loadingViewdata,
    data: { viewdatas } = {
      viewdatas: [],
    },
    refetch: refetchViewdatas,
  } = useQuery(GET_VIEWDATAS)

  const {
    loading: loadingFarms,
    data: { farms } = { farms: [] },
    refetch: refetchFarms,
  } = useQuery(GET_FARMS)

  const { handleSubmit, register, errors, setValue } = useForm()

  const handleSearch = () => {
    if (farm && from && to)
      refetchViewdatas({
        farmcode: farm,
        from,
        to,
      })
  }

  const handleSaveCsv = () => {
    const fromDate = from.split('/')
    const toDate = to.split('/')

    window.open(
      `${restApi}/viewdata_csv.php?from=${fromDate[2]}-${fromDate[0]}-${fromDate[1]}&to=${toDate[2]}-${toDate[0]}-${toDate[1]}&farmcode=${farm}`,
      '_blank'
    )
  }

  return (
    <div className={classes.root}>
      <DataEntry
        key={+new Date() + Math.random()}
        setSelected={setSelected}
        setAwb={setAwb}
        data={awb}
        open={open}
        setOpen={setOpen}
        refetch={handleSearch}
      />
      <Button
        variant="contained"
        style={{ marginBottom: 20 }}
        onClick={() => {
          setOpen(true)
          setAwb()
        }}
      >
        New (F2)
      </Button>

      <Grid
        container
        spacing={3}
        style={{
          marginBottom: 20,
        }}
      >
        {!loadingFarms && (
          <Grid item xs={12} md={3}>
            <FormControl /* error={errors.Feed && true} */ fullWidth>
              <InputLabel
                classes={{ root: classes.rootInputlabel }}
                shrink
                htmlFor="Farm"
              >
                Farm
              </InputLabel>
              <Select
                labelId="Farm"
                id="Farm"
                name="Farm"
                value={farm}
                onChange={(e) => {
                  setFarm(e.target.value)
                }}
                input={
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                  />
                }
                /*  inputRef={register({
            required: 'This field is Required'
          })} */
              >
                {farms.map((a, i) => (
                  <MenuItem key={i} value={a.FarmCode}>
                    {a.FarmCode}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {/* {errors.Feed && errors.Feed.message} */}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item sm={2} xs={12}>
          <DatepickerField
            name="From"
            label="From"
            value={from ? from : null}
            fullWidth
            onChange={(e, value) => {
              setFrom(value)
            }}
            inputRef={register({
              required: 'Required',
            })}
            autoComplete="off"
            error={errors.From}
            helperText={errors.From && errors.From.message}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <DatepickerField
            name="To"
            label="To"
            value={to ? to : null}
            fullWidth
            onChange={(e, value) => {
              setTo(value)
            }}
            inputRef={register({
              required: 'Required',
            })}
            autoComplete="off"
            error={errors.To}
            helperText={errors.To && errors.To.message}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Button
            disabled={!to || !from || !farm}
            variant="contained"
            color="primary"
            style={{
              marginTop: 20,
              marginRight: 10,
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
          {/* <Button
            variant="contained"
            disabled={viewdatas.length === 0}
            
            type="submit"
            style={{
              marginTop: 20,
              marginRight: 20,
            }}
            onClick={handlePreviewInvoice}
            startIcon={<VisibilityIcon />}
          >
            Preview Invoices
          </Button>
          <Button
            variant="contained"
            disabled={viewdatas.length === 0}
            color="primary"
            type="submit"
            startIcon={<DirectionsRunIcon />}
            style={{
              marginTop: 20,
            }}
            onClick={() => {
              setConfirm(true)
            }}
          >
            Generate Invoices
          </Button> */}
          <Button
            variant="contained"
            disabled={viewdatas.length === 0}
            color="primary"
            type="button"
            startIcon={<GetAppIcon />}
            style={{
              marginTop: 20,
            }}
            onClick={() => {
              handleSaveCsv()
            }}
          >
            Save CSV
          </Button>
        </Grid>
      </Grid>
      {loadingViewdata && <Loading />}
      {!loadingViewdata && (
        <TableHalf
          rowsPerPage={10}
          clickOnSelect={true}
          disableSelected={false}
          hidePagination={false}
          hideChange={true}
          hideDelete={true}
          selected={selected}
          setSelected={setSelected}
          setOpen={setOpen}
          tableState={setAwb}
          tableData={viewdatas}
          tableHead={tableHead}
        ></TableHalf>
      )}
    </div>
  )
}
