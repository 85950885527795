import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

//import CurrencyFormat from 'react-currency-format'

import TextField from '@material-ui/core/TextField'
//import useForm from 'react-hook-form'
//import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
//import Cookies from 'js-cookie'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Cookies from 'js-cookie'

const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  tablecell2: {
    padding: '0!important',
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  table: {
    backgroundColor: '#ddd',
    color: theme.palette.common.white,
  },
  tablecell: {
    backgroundColor: '#b1b1b1',
    whiteSpace: 'nowrap',
    color: theme.palette.common.white,
    padding: '5px 5px!important',
    width: 80,
    textAlign: 'center',
    border: '1px solid #f5f5f5',
    fontSize: 10,
  },
  tablebutton: {
    padding: 5,
    minWidth: 0,
    fontSize: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialog: {
    WebkitOverflowScrolling: 'touch',
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
    margin: 'auto!important',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2), paddingTop: 0 },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function NetDialogSelection(props) {
  const classes = useStyles()

  const [tabledata, setTabledata] = useState([])
  const [tablesetup, setTablesetup] = useState()
  const [nets, setNets] = useState([])

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function tabProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  useEffect(() => {
    if (props.farm && props.farms && props.farms.length > 0) {
      const farmSetup = props.farms.find((a) => a.FarmCode == props.farm)

      if (!farmSetup) return

      setTablesetup(farmSetup)

      for (let a = 1; a <= farmSetup.TotalRows; a++) {
        tabledata[a] = { colA: [], colB: [] }

        for (let b = 1; b <= farmSetup.ColsA; b++) {
          const netvalue = props.nets.find(
            (z) => z.NetCode == `A${a}-${b}` && z.FarmCode == props.farm
          )

          let color = '#515151'
          if (netvalue) {
            console.log('here', netvalue)

            if (netvalue.Batches) {
              const colorvalue = netvalue.Batches.split(',')[0]

              if (colorvalue)
                color = colorvalue.split('|')[2].split('-').join(',')
            }

            tabledata[a].colA.push({
              ...netvalue,
              color: color,
            })
          } else {
            tabledata[a].colA.push({ NetCode: `A${a}-${b}` })
          }
        }

        for (let b = 1; b <= farmSetup.ColsB; b++) {
          const netvalue = props.nets.find(
            (z) => z.NetCode == `B${a}-${b}` && z.FarmCode == props.farm
          )

          let color = '#515151'

          //console.log(netvalue)
          if (netvalue) {
            if (netvalue.Batches) {
              const colorvalue = netvalue.Batches.split(',')[0]

              //console.log(colorvalue)

              if (colorvalue)
                color = colorvalue.split('|')[2].split('-').join(',')
            }

            tabledata[a].colB.push({
              ...netvalue,
              color: color,
            })
          } else {
            tabledata[a].colB.push({ NetCode: `B${a}-${b}` })
          }
        }
      }
    }
  }, [props])

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="farm-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="farm-dialog">
            Farm {props.farm}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            classes={{ root: classes.rootContainer }}
            className={classes.dialogContent}
          >
            <CssBaseline />
            <Container className={classes.container}>
              <StyledTabs
                className={classes.border}
                variant="scrollable"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="A Side" {...tabProps(0)} />
                <StyledTab label="B Side" {...tabProps(1)} />
              </StyledTabs>
              <TabPanel value={value} index={0}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {tabledata.length > 0 &&
                        tabledata.map((a, i) => (
                          <TableRow key={i}>
                            {a.colA &&
                              a.colA.map((b, j) => (
                                <React.Fragment key={j}>
                                  {b.color && (
                                    <TableCell
                                      style={{ padding: '0!important' }}
                                      classes={{ root: classes.tablecell2 }}
                                      align="center"
                                    >
                                      <Button
                                        style={{
                                          whiteSpace: 'nowrap',
                                          padding: '5px 5px!important',
                                          backgroundColor: b.color
                                            ? `rgb(${b.color})`
                                            : 'transparent',
                                          textShadow: '2px 2px black',
                                          color: 'white',
                                        }}
                                        onFocus={true}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          props.setOpen(false)
                                          props.setNet(b.NetCode)
                                        }}
                                      >
                                        {b.NetCode}
                                      </Button>
                                    </TableCell>
                                  )}
                                  {!b.color && (
                                    <TableCell
                                      classes={{ root: classes.tablecell }}
                                      align="center"
                                    >
                                      {b.NetCode}
                                    </TableCell>
                                  )}
                                </React.Fragment>
                              ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {tabledata.length > 0 &&
                        tabledata.map((a, i) => (
                          <TableRow key={i}>
                            {a.colB &&
                              a.colB.map((b, j) => (
                                <React.Fragment key={j}>
                                  {b.color && (
                                    <TableCell
                                      style={{ padding: '0!important' }}
                                      classes={{ root: classes.tablecell2 }}
                                      align="center"
                                    >
                                      <Button
                                        style={{
                                          whiteSpace: 'nowrap',
                                          padding: '5px 5px!important',
                                          backgroundColor: b.color
                                            ? `rgb(${b.color})`
                                            : 'transparent',
                                          textShadow: '2px 2px black',
                                          color: 'white',
                                        }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          props.setOpen(false)
                                          props.setNet(b.NetCode)
                                        }}
                                      >
                                        {b.NetCode}
                                      </Button>
                                    </TableCell>
                                  )}
                                  {!b.color && (
                                    <TableCell
                                      classes={{ root: classes.tablecell }}
                                      align="center"
                                    >
                                      {b.NetCode}
                                    </TableCell>
                                  )}
                                </React.Fragment>
                              ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </Container>

            {/* <pre>{JSON.stringify(tabledata, 0, 4)}</pre>
              <pre>{JSON.stringify(tablesetup, 0, 4)}</pre> */}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <React.Fragment></React.Fragment>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
