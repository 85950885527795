import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { GET_BATCH_STOCK_PURCHASING } from '../graphql/batch'
import { GET_FARMS } from '../graphql/farm'
import ConfirmationDialog from '../common/confirmationDialog'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import PrintIcon from '@material-ui/icons/Print'
import RefreshIcon from '@material-ui/icons/Refresh'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import AutocompleteField from '../common/autocompleteField'
import Loading from '../common/loading'
import ClosingDialog from './closingnewdialog'
import CostingDisplayDialog from './closingDisplayDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default function ClosingFishStock() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [closing, setClosing] = useState()
  const [openClosing, setOpenClosing] = useState(false)
  const [openClosingDetail, setOpenClosingDetail] = useState(false)
  const [farm, setFarm] = useState('BA')
  const [loadData, setLoadData] = useState(false)

  const {
    loading: loadingFarms,
    data: { farms } = { farms: [] },
    refetch: refetchFarms,
  } = useQuery(GET_FARMS)

  const {
    loading: loadingReport,
    data: { batchstockpurchasing } = { batchstockpurchasing: [] },
    refetch: refetchReport,
  } = useQuery(GET_BATCH_STOCK_PURCHASING, {
    variables: { FarmCode: farm },
    skip: !loadData,
  })

  const handleClickOpen = () => {
    setOpenClosing(true)
  }

  const tableHead = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'FarmCode',
      label: 'Farm',
      width: 150,
    },
    {
      id: 'SpeciesName',
      label: 'Species',
      width: 450,
    } /* ,
    {
      id: 'NetCode',
      label: 'Net Code',
      width: 150,
    } */,
    {
      id: 'YearOfPurchase',
      label: 'Batch Year',
      width: 150,
    },
    {
      id: 'BatchCode',
      label: 'Batch No',
      width: 150,
    },
    {
      id: 'NetQtyPcs',
      numeric: true,
      weight: true,
      label: 'Initial Pcs',
      width: 150,
    },
    {
      id: 'SalesPcs',
      weight: true,
      numeric: true,
      label: 'Sales Pcs',
      width: 100,
    },
    {
      id: 'SalesKg',
      weight: true,
      numeric: true,
      label: 'Sales KG',
      width: 100,
    },
    {
      id: 'Pcs',
      weight: true,
      numeric: true,
      label: 'Current Pcs',
      width: 100,
    },
    {
      id: 'SpeciesCode',
      label: 'Species Code',
      width: 150,
    },
    {
      id: 'PurchaseSize',
      label: 'Purchased Size(KG)',
      width: 150,
    },
    {
      id: 'InPcs',
      label: 'In (Pcs)',
      width: 150,
    },
    {
      id: 'DeadPcs',
      label: 'Dead (Pcs)',
      width: 150,
    } /* ,
    {
      id: 'QtyPcs',
      label: 'Qty (Pcs)',
      width: 150,
    } */,
    {
      id: 'TotalWeight',
      weight: true,
      numeric: true,
      label: 'Est. Total Weight (KG)',
      width: 100,
    },
    {
      id: 'AverageSize',
      weight: true,
      numeric: true,
      label: 'Avg. Size (KG)',
      width: 100,
    },
    {
      id: 'TotalFeed',
      weight: true,
      numeric: true,
      label: 'Total Feed (KG)',
      width: 100,
    },
    {
      id: 'TotalFeedIBB',
      weight: true,
      numeric: true,
      label: 'Total Feed IBB (KG)',
      width: 100,
    },
    {
      id: 'TotalFeedIFF',
      weight: true,
      numeric: true,
      label: 'Total Feed IFF (KG)',
      width: 100,
    },
    {
      id: 'TotalFeedPallet',
      weight: true,
      numeric: true,
      label: 'Total Feed Pallet (KG)',
      width: 100,
    },
  ]
  /* 
  if (!loadingReport) return <Loading /> */

  return (
    <div className={classes.root}>
      {/* {!loadingFarms && (
        <Grid container classes={{ root: classes.rootContainer }}>
          <Grid item xs={6} md={2}>
            <FormControl fullWidth>
              <InputLabel
                classes={{ root: classes.rootInputlabel }}
                shrink
                htmlFor="Farm"
              >
                Farm
              </InputLabel>
              <Select
                labelId="Farm"
                id="Farm"
                name="Farm"
                value={farm}
                onChange={(e) => {
                  setFarm(e.target.value)
                }}
                input={
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                  />
                }
              >
                {farms.map((a, i) => (
                  <MenuItem key={i} value={a.FarmCode}>
                    {a.FarmCode}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )} */}
      <Grid container spacing={0}>
        {/* <Button variant="contained" color="primary" onClick={handleClickOpen}>
            New
          </Button> */}
        <Button
          variant="outlined"
          color="primary"
          /* style={{ marginLeft: 5 }} */
          startIcon={<RefreshIcon />}
          onClick={() => {
            setLoadData(true)
          }}
        >
          Get Data
        </Button>
        {loadingReport && (
          <Grid item style={{ paddingTop: 20 }} xs={12} sm={12}>
            <Loading />
          </Grid>
        )}
        {!loadingReport && (
          <Grid item xs={12} sm={12}>
            <TableHalf
              rowsPerPage={100}
              clickOnSelect={true}
              hidePagination={false}
              hideSearch={false}
              hideDelete={true}
              hideChange={true}
              selected={selected}
              setSelected={setSelected}
              tableState={setClosing}
              tableData={batchstockpurchasing}
              setOpen={setOpenClosingDetail}
              tableHead={tableHead}
            ></TableHalf>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
