import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Cookies from 'js-cookie'
import Farm from './farm'
import Net from './net'
import Feed from './feed'
import Batch from './batch'
import Species from './species'
import StockTake from './stocktake'

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: '#263238',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  container: {
    marginLeft: 0,
    padding: theme.spacing(0),
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: 'transparent',
  },
  border: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function Document() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function tabProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const access = {
    view1: ['Management'],
    view2: ['Farmadmin'],
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {access.view1.find((access) => access == Cookies.get('Department')) && (
        <div className={classes.root}>
          <div className={classes.demo2}>
            <StyledTabs
              className={classes.border}
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              <StyledTab label="Net" {...tabProps(0)} />
              <StyledTab label="Farm" {...tabProps(1)} />
              <StyledTab label="Batch" {...tabProps(2)} />
              <StyledTab label="Feed" {...tabProps(3)} />
              <StyledTab label="Species" {...tabProps(4)} />
              <StyledTab label="Feed Stock In" {...tabProps(5)} />
            </StyledTabs>
            <TabPanel value={value} index={1}>
              <Container className={classes.container} maxWidth="sm">
                <Farm />
              </Container>
            </TabPanel>
            <TabPanel value={value} index={0}>
              <Container className={classes.container} maxWidth="md">
                <Net />
              </Container>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Container className={classes.container} maxWidth="sm">
                <Batch />
              </Container>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Container className={classes.container} maxWidth="sm">
                <Feed />
              </Container>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Container className={classes.container} maxWidth="sm">
                <Species />
              </Container>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Container className={classes.container} maxWidth="md">
                <StockTake stockType="FEED" />
              </Container>
            </TabPanel>
          </div>
        </div>
      )}
      {access.view2.find((access) => access == Cookies.get('Department')) && (
        <Container className={classes.container} maxWidth="md">
          <div className={classes.root}>
            <div className={classes.demo2}>
              <StyledTabs
                className={classes.border}
                variant="scrollable"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="Farm" {...tabProps(0)} />
                <StyledTab label="Net" {...tabProps(1)} />
                <StyledTab label="Batch" {...tabProps(2)} />
                <StyledTab label="Feed" {...tabProps(3)} />
                <StyledTab label="Species" {...tabProps(4)} />
                <StyledTab label="Feed Stock In" {...tabProps(5)} />
              </StyledTabs>
              <TabPanel value={value} index={0}>
                <Container className={classes.container} maxWidth="sm">
                  <Farm />
                </Container>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Container className={classes.container} maxWidth="md">
                  <Net />
                </Container>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Container className={classes.container} maxWidth="sm">
                  <Batch />
                </Container>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Container className={classes.container} maxWidth="sm">
                  <Feed />
                </Container>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Container className={classes.container} maxWidth="sm">
                  <Species />
                </Container>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <Container className={classes.container} maxWidth="md">
                  <StockTake stockType="FEED" />
                </Container>
              </TabPanel>
            </div>
          </div>
        </Container>
      )}
    </React.Fragment>
  )
}
