import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TableHalf from '../common/tableHalf'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import InputBase from '@material-ui/core/InputBase'
import Moment from 'moment'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'
import GetAppIcon from '@material-ui/icons/GetApp'
import {
  GET_CLOSINGDETAILS,
  REMOVE_CLOSINGHEADER,
  GET_CLOSINGHEADERS,
} from '../graphql/closing'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import AutocompleteField from '../common/autocompleteField'
import { useSnackbar } from 'notistack'

const restApi = 'https://frapp.cloud:3670'

const restApi2 = 'https://frapp.cloud/fishfarm'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))
const tableHead2 = [
  { label: '', col: 1 },
  { label: '', col: 1 },
  { label: 'Opening Balance', col: 2 },
  { label: 'Stock In', col: 2 },
  { label: 'Stock Out', col: 2 },
  { label: 'Closing Balance', col: 2 },
]
const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'ItemID',
    label: 'Feed Code',
  },
  {
    id: 'OpeningBalanceBags',
    weight: true,
    numeric: true,
    label: 'Bags',
    width: 100,
  },
  {
    id: 'OpeningBalanceKG',
    weight: true,
    numeric: true,
    label: 'KG',
    width: 100,
  },
  {
    id: 'StockInBags',
    weight: true,
    numeric: true,
    label: 'Bags',
    width: 100,
  },
  {
    id: 'StockInKG',
    weight: true,
    numeric: true,
    label: 'KG',
    width: 100,
  },
  {
    id: 'StockOutBags',
    weight: true,
    numeric: true,
    label: 'Bags',
    width: 100,
  },
  {
    id: 'StockOutKG',
    weight: true,
    numeric: true,
    label: 'KG',
    width: 100,
  },
  {
    id: 'ClosingBalanceBags',
    weight: true,
    numeric: true,
    label: 'Bags',
    width: 100,
  },
  {
    id: 'ClosingBalanceKG',
    weight: true,
    numeric: true,
    label: 'KG',
    width: 100,
  },
]

export default function OfficeStaffPayrollDetailDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [selected, setSelected] = useState([])
  const [closing, setClosing] = useState()
  const [confirm, setConfirm] = useState(false)
  const [open, setOpen] = useState(false)

  const { data: { closingdetails } = { closingdetails: [] } } = useQuery(
    GET_CLOSINGDETAILS,
    {
      variables: {
        ClosingID: props.data && props.data.id,
      },
      skip: !props.data,
    }
  )

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CLOSINGHEADERS,
      variables: { FarmCode: props && props.farm },
    })

    if (data.removeclosingheader.Error) {
      enqueueSnackbar(data.removeclosingheader.Error, {
        variant: 'error',
      })
      return
    }

    if (!data.removeclosingheader.id) return
    const latest = old.closingheaders.filter(
      (a) => a.id != data.removeclosingheader.id
    )
    cache.writeQuery({
      query: GET_CLOSINGHEADERS,
      variables: { FarmCode: props && props.farm },
      data: { closingheaders: [...latest] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setClosing()
  }

  const [removeClosing] = useMutation(REMOVE_CLOSINGHEADER, {
    update: deleteCache,
  })

  const handleClickDelete = () => {
    removeClosing({
      variables: {
        id: props.data && props.data.id,
      },
    })
    setConfirm(false)
  }

  useEffect(() => {
    if (!props.data) return
  }, [props])

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to remove this Closing?"
        okButton="Yes"
        title="Remove Closing"
      />
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Closing - {props.data && props.data.FarmCode} -{' '}
              {props.data &&
                Moment(props.data.ClosingDate)
                  .tz('Asia/Kuala_Lumpur')
                  .format('DD-MM-YYYY')}
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                {closingdetails.length > 0 && (
                  <Grid
                    item
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    md={12}
                    xs={12}
                  >
                    {/* <h3 style={{ marginBottom: 0 }}>Sales</h3>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        startIcon={<GetAppIcon />}
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                        onClick={() => {
                          handleSaveCsv()
                        }}
                      >
                        Save CSV
                      </Button> */}
                    <TableHalf
                      hidePagination={false}
                      disableSelected={true}
                      clickOnSelect={true}
                      hideSearch={true}
                      hideDelete={true}
                      hideChange={true}
                      selected={selected}
                      setSelected={setSelected}
                      tableState={setClosing}
                      setOpen={setOpen}
                      tableData={closingdetails}
                      tableHead={tableHead}
                      rowsPerPage={10}
                      tableHead2={tableHead2}
                    ></TableHalf>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {props.data && (
                <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {/* <Button
                  variant="contained"
                  
                  color="primary"
                  type="submit"
                >
                  Save
                </Button> */}
            </DialogActions>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
