import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import { GET_CATEGORIES } from '../graphql/category'
import {
  CREATE_FEED,
  UPDATE_FEED,
  GET_FEEDS,
  REMOVE_FEED,
} from '../graphql/feed'
import { GET_FEEDCOSTINGS } from '../graphql/feedcosting'
import {
  GET_NETS,
  GET_MAINNET_SELECT,
  UPDATE_NET,
  CREATE_NET,
} from '../graphql/net'

import { GET_NETBATCHES } from '../graphql/batch'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import AutocompleteField from '../common/autocompleteField'
import TableHalf from '../common/tableHalf'

import FeedCostingDialog from './feedcostingDialog'
import RefreshIcon from '@material-ui/icons/Refresh'

const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'EffectiveDate',
    date: true,
    label: 'Effective Date',
  },
  {
    id: 'CostPerKg',
    label: 'Cost Per KG',
  },
  {
    id: 'KGToBags',
    label: 'KG Per Bag',
  },
]

export default function FeedDialog(props) {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [feed, setFeed] = useState()

  const { handleSubmit, register, errors, setValue } = useForm()
  const [mainnet, setMainnet] = useState()

  const {
    loading: loadingFeedCostings,
    data: { feedcostings } = { feedcostings: [] },
    refetch: refetchFeedCostings,
    error,
  } = useQuery(GET_FEEDCOSTINGS, {
    variables: { id: props.data && props.data.id },
  })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_FEEDS,
    })
    const latest = data.insertfeed
    cache.writeQuery({
      query: GET_FEEDS,
      data: { feeds: [latest, ...old.feeds] },
    })

    props.setOpen(false)
    props.setSelected([latest.id])
    props.setFeed(latest)
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_FEEDS,
    })
    const latest = data.updatefeed
    const foundIndex = old.feeds.findIndex((item) => item.id === latest.id)
    old.feeds.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_FEEDS,
      data: { feeds: [...old.feeds] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setFeed()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_FEEDS,
    })
    if (data.removefeed) {
      const latest = old.feeds.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_FEEDS,
        data: { feeds: [...latest] },
      })
      props.setOpen(false)
      props.setSelected([])
      props.setFeed()
    }
  }

  const [insertFeed] = useMutation(CREATE_FEED, { update: addCache })
  const [updateFeed] = useMutation(UPDATE_FEED, {
    update: updateCache,
  })
  const [removeFeed] = useMutation(REMOVE_FEED, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateFeed({
        variables: {
          id: props.data.id,
          FishfeedCode: values.FishfeedCode,
          FishfeedName: values.FishfeedName,
          KGToBags: parseFloat(values.KGToBags),
          FishfeedDesc: values.FishfeedDesc,
        },
      })
    } else {
      insertFeed({
        variables: {
          FishfeedCode: values.FishfeedCode,
          FishfeedName: values.FishfeedName,
          KGToBags: parseFloat(values.KGToBags),
          FishfeedDesc: values.FishfeedDesc,
        },
      })
    }
  }

  const handleRemoveFeed = () => {
    if (props.data && !props.data.id) return
    removeFeed({
      variables: {
        id: props.data.id,
      },
    })
    setConfirmDelete(false)
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleRemoveFeed}
        confirm={confirmDelete}
        setConfirm={setConfirmDelete}
        message="Confirm remove Feed?"
        okButton="Yes"
        title="Remove Feed"
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="farm-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="farm-dialog">
            {props.data && 'Update Feed'}
            {!props.data && 'New Feed'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormControl
                        error={errors.FishfeedCode && true}
                        fullWidth
                      >
                        <InputLabel
                          classes={{ root: classes.rootInputlabel }}
                          shrink
                          htmlFor="FishfeedCode"
                        >
                          Code
                        </InputLabel>
                        <InputBase
                          classes={{
                            root: classes.rootBootstrap,
                            input: classes.inputBootstrap,
                          }}
                          id="FishfeedCode"
                          name="FishfeedCode"
                          label="FishfeedCode"
                          defaultValue={props.data && props.data.FishfeedCode}
                          fullWidth
                          margin="dense"
                          autoComplete="off"
                          inputProps={{
                            maxLength: 200,
                            readOnly: props.data ? true : false,
                          }}
                          inputRef={register({
                            required: 'This field is Required',
                          })}
                        />

                        <FormHelperText>
                          {/* {errors.FishfeedCode && errors.FishfeedCode.message} */}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                      <FormControl
                        error={errors.FishfeedName && true}
                        fullWidth
                      >
                        <InputLabel
                          classes={{ root: classes.rootInputlabel }}
                          shrink
                          htmlFor="FishfeedName"
                        >
                          Name
                        </InputLabel>
                        <InputBase
                          classes={{
                            root: classes.rootBootstrap,
                            input: classes.inputBootstrap,
                          }}
                          id="FishfeedName"
                          name="FishfeedName"
                          label="FishfeedName"
                          fullWidth
                          margin="dense"
                          autoComplete="off"
                          defaultValue={props.data && props.data.FishfeedName}
                          inputProps={{
                            maxLength: 200,
                          }}
                          inputRef={register({})}
                        />

                        <FormHelperText>
                          {errors.FishfeedName && errors.FishfeedName.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                      <FormControl error={errors.KGToBags && true} fullWidth>
                        <InputLabel
                          classes={{ root: classes.rootInputlabel }}
                          shrink
                          htmlFor="KGToBags"
                        >
                          KG Per Bag
                        </InputLabel>
                        <InputBase
                          classes={{
                            root: classes.rootBootstrap,
                            input: classes.inputBootstrap,
                          }}
                          id="KGToBags"
                          name="KGToBags"
                          label="KG Per Bag"
                          fullWidth
                          margin="dense"
                          autoComplete="off"
                          defaultValue={props.data && props.data.KGToBags}
                          inputProps={{
                            maxLength: 200,
                          }}
                          inputRef={register({})}
                        />

                        <FormHelperText>
                          {errors.KGToBags && errors.KGToBags.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <FormControl
                        error={errors.FishfeedDesc && true}
                        fullWidth
                      >
                        <InputLabel
                          classes={{ root: classes.rootInputlabel }}
                          shrink
                          htmlFor="FishfeedDesc"
                        >
                          Description
                        </InputLabel>
                        <InputBase
                          classes={{
                            root: classes.rootBootstrap,
                            input: classes.inputBootstrap,
                          }}
                          id="FishfeedDesc"
                          name="FishfeedDesc"
                          label="FishfeedDesc"
                          fullWidth
                          multiline
                          margin="dense"
                          autoComplete="off"
                          defaultValue={props.data && props.data.FishfeedDesc}
                          inputProps={{
                            maxLength: 200,
                          }}
                          inputRef={register({})}
                        />

                        <FormHelperText>
                          {errors.FishfeedDesc && errors.FishfeedDesc.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Button
                        margin="dense"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {/* <pre>{JSON.stringify(props.data)}</pre> */}

              <FeedCostingDialog
                setOpen={setOpen}
                setSelected={setSelected}
                setFeed={setFeed}
                data={feed}
                open={open}
                feed={props.data}
              />
              {props.data && (
                <Grid
                  style={{ borderLeft: '1px solid grey' }}
                  item
                  md={6}
                  xs={12}
                >
                  <Button
                    margin="dense"
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                      setOpen(true)
                      setSelected([])
                      setFeed()
                    }}
                  >
                    New
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: 5 }}
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                      refetchFeedCostings()
                    }}
                  >
                    Refresh
                  </Button>
                  <br />
                  <br />
                  <TableHalf
                    clickOnSelect={true}
                    hideSearch={true}
                    hidePagination={true}
                    hideChange={true}
                    hideDelete={true}
                    setConfirm={setConfirm}
                    selected={selected}
                    setSelected={setSelected}
                    tableState={setFeed}
                    tableData={feedcostings}
                    setOpen={setOpen}
                    tableHead={tableHead}
                  ></TableHalf>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <React.Fragment>
              <IconButton
                style={{ marginRight: 10 }}
                onClick={() => {
                  setConfirmDelete(true)
                }}
                edge="end"
                aria-label="comments"
              >
                <DeleteIcon />
              </IconButton>
            </React.Fragment>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
