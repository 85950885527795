import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import { GET_CATEGORIES } from '../graphql/category'
import { CREATE_FARM, UPDATE_FARM, GET_FARMS } from '../graphql/farm'
import {
  GET_FEEDCOSTINGS,
  UPDATE_FEEDCOSTINGS,
  CREATE_FEEDCOSTINGS,
  REMOVE_FEEDCOSTINGS,
} from '../graphql/feedcosting'
import {
  GET_NETBATCHES,
  GET_BATCHES,
  CREATE_NETBATCH,
  UPDATE_NETBATCH,
  REMOVE_NETBATCH,
} from '../graphql/batch'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import AutocompleteField from '../common/autocompleteField'
import DatepickerField from '../common/datepickerField'

const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default function FeedCostingDialog(props) {
  const classes = useStyles()
  const [confirm, setConfirm] = useState(false)
  const { handleSubmit, register, errors, setValue } = useForm()
  const [batchcode, setBatchcode] = useState()
  const [batchid, setBatchid] = useState()
  const [effectiveDate, setEffectiveDate] = useState()

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_FEEDCOSTINGS,
      variables: {
        id: props.feed && props.feed.id,
      },
    })
    const latest = data.insertfeedcosting
    cache.writeQuery({
      query: GET_FEEDCOSTINGS,
      variables: {
        id: props.feed && props.feed.id,
      },
      data: { feedcostings: [latest, ...old.feedcostings] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setFeed()
  }

  const updateCache = (cache, { data }) => {
    /* const old = cache.readQuery({
      query: GET_NETBATCHES,
      variables: {
        NetCode: props.net && props.net.NetCode,
        FarmCode: props.net && props.net.FarmCode,
      },
    })
    const latest = data.updatenetbatch
    const foundIndex = old.netbatches.findIndex((item) => item.id === latest.id)
    old.netbatches.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_NETBATCHES,
      variables: {
        NetCode: props.net && props.net.NetCode,
        FarmCode: props.net && props.net.FarmCode,
      },
      data: { netbatches: [...old.netbatches] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setBatch() */
    props.setOpen(false)
    props.setSelected([])
    props.setFeed()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_FEEDCOSTINGS,
      variables: {
        id: props.feed && props.feed.id,
      },
    })
    if (data.removefeedcosting) {
      const latest = old.feedcostings.filter(
        (item) => item.id !== props.data.id
      )
      cache.writeQuery({
        query: GET_FEEDCOSTINGS,
        variables: {
          id: props.feed && props.feed.id,
        },
        data: { feedcostings: [...latest] },
      })
      props.setSelected([])
      props.setBatch()
    }
  }

  const [updateFeedcosting] = useMutation(UPDATE_FEEDCOSTINGS, {
    update: updateCache,
  })
  const [insertFeedcosting] = useMutation(CREATE_FEEDCOSTINGS, {
    update: addCache,
  })
  const [removeFeedcosting] = useMutation(REMOVE_FEEDCOSTINGS, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateFeedcosting({
        variables: {
          id: props.data.id,
          FishfeedID: props.data.FishfeedID,
          CostPerKg: parseFloat(values.CostPerKg),
          EffectiveDate: effectiveDate,
        },
      })
    } else {
      insertFeedcosting({
        variables: {
          FishfeedID: props.feed.id,
          CostPerKg: parseFloat(values.CostPerKg),
          EffectiveDate: effectiveDate,
        },
      })
    }
  }

  const handleClickDelete = () => {
    removeFeedcosting({
      variables: {
        id: props.data.id.toString(),
      },
    })
    setConfirm(false)
    props.setOpen(false)
    props.setSelected([])
    props.setFeed()
  }

  useEffect(() => {
    if (!props.data) return

    if (props.data.EffectiveDate) setEffectiveDate(props.data.EffectiveDate)
  }, [props])

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure? Costing?"
        okButton="Yes"
        title="Are you sure?"
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="farm-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="farm-dialog">
            {props.data && 'Update Costing'}
            {!props.data && 'New Costing'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container classes={{ root: classes.rootContainer }}>
                <Grid item xs={6} md={6}>
                  <DatepickerField
                    name="EffectiveDate"
                    label="EffectiveDate"
                    value={effectiveDate ? effectiveDate : null}
                    fullWidth
                    onChange={(e, value) => {
                      setEffectiveDate(value)
                    }}
                    inputRef={register({
                      required: 'Required',
                    })}
                    autoComplete="off"
                    error={errors.EffectiveDate}
                    helperText={
                      errors.EffectiveDate && errors.EffectiveDate.message
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <FormControl error={errors.Pcs && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="CostPerKg"
                    >
                      Cost Per Kg
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="CostPerKg"
                      name="CostPerKg"
                      label="CostPerKg"
                      fullWidth
                      type="number"
                      inputProps={{ min: '0', step: '0.01' }}
                      margin="dense"
                      autoComplete="off"
                      defaultValue={props.data && props.data.CostPerKg}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {errors.CostPerKg && errors.CostPerKg.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <React.Fragment>
                <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton>
                <Button
                  margin="dense"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </React.Fragment>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
