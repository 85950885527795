import { useQuery } from '@apollo/react-hooks'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, { useMemo, useState } from 'react'
import Loading from '../common/loading'
import TableHalf from '../common/tableHalf'
import { GET_BATCH_STOCK_CURRENT_HARVESTED } from '../graphql/batch'
import { GET_FARMS } from '../graphql/farm'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default function ClosingFishStockHarvested() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [closing, setClosing] = useState()
  const [openClosing, setOpenClosing] = useState(false)
  const [openClosingDetail, setOpenClosingDetail] = useState(false)
  const [farm, setFarm] = useState()
  const [net, setNet] = useState()
  const [speciesCode, setSpeciesCode] = useState()
  const [speciesName, setSpeciesName] = useState()
  const [loadData, setLoadData] = useState(false)

  const {
    loading: loadingFarms,
    data: { farms } = { farms: [] },
    refetch: refetchFarms,
  } = useQuery(GET_FARMS)

  const {
    loading: loadingReport,
    data: { batchstockcurrentharvested } = { batchstockcurrentharvested: [] },
    refetch: refetchReport,
  } = useQuery(GET_BATCH_STOCK_CURRENT_HARVESTED, {
    fetchPolicy: 'network-only',
  })

  const handleSaveCsv = () => {
    window.open(
      `https://ksaquaadmin.frapp.cloud/report/sf_current_report_harvested_csv.php`,
      '_blank'
    )
  }

  const handleSaveIMCsv = () => {
    window.open(
      `https://ksaquaadmin.frapp.cloud/report/ksaqua_current_report_IM_Mortality_csv.php`,
      '_blank'
    )
  }

  const tableHead = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'FarmCode',
      label: 'Farm Code',
      width: 150,
    },
    {
      id: 'NetCode',
      label: 'Net Code',
      width: 150,
    },
    {
      id: 'BatchCode',
      label: 'Batch Code',
      width: 150,
    },
    /* {
      id: 'CreatedOn',
      date: true,
      label: 'Stock Date',
      width: 150,
    },
    {
      id: 'LastCountDate',
      date: true,
      label: 'Last Count Date',
      width: 150,
    }, */
    {
      id: 'SpeciesCode',
      label: 'Species Code',
      width: 150,
    },
    {
      id: 'SpeciesName',
      label: 'Species Name',
      width: 450,
    },

    {
      id: 'SalesPcs',
      weight: true,
      numeric: true,
      label: 'Sales (Pcs)',
      width: 150,
    },
    {
      id: 'SalesKg',
      weight: true,
      numeric: true,
      label: 'Sales (Kg)',
      width: 150,
    },
  ]

  const filteredFarm = useMemo(
    () =>
      batchstockcurrentharvested
        .filter(
          (value, index, self) =>
            self.map((x) => x.FarmCode).indexOf(value.FarmCode) == index
        )
        .sort((a, b) => a.FarmCode.localeCompare(b.FarmCode)),
    [batchstockcurrentharvested]
  )

  const filteredNet = useMemo(
    () =>
      batchstockcurrentharvested
        .filter((a) => a.FarmCode === farm)
        .filter(
          (value, index, self) =>
            self.map((x) => x.NetCode).indexOf(value.NetCode) == index
        ),
    [batchstockcurrentharvested, farm]
  )

  const filteredSpeciesCode = useMemo(
    () =>
      batchstockcurrentharvested
        .filter((a) => a.FarmCode === farm)
        .filter(
          (value, index, self) =>
            self.map((x) => x.SpeciesCode).indexOf(value.SpeciesCode) == index
        ),
    [batchstockcurrentharvested, farm, net]
  )

  const filteredSpeciesName = useMemo(
    () =>
      batchstockcurrentharvested
        .filter((a) => a.FarmCode === farm)
        .filter(
          (value, index, self) =>
            self.map((x) => x.SpeciesName).indexOf(value.SpeciesName) == index
        )
        .filter((a) => a.SpeciesName)
        .sort(
          (a, b) => a.SpeciesName && a.SpeciesName.localeCompare(b.SpeciesName)
        ),
    [batchstockcurrentharvested, farm, net, speciesCode]
  )

  const filteredData = useMemo(() => {
    let output = batchstockcurrentharvested

    if (farm) output = output.filter((x) => x.FarmCode == farm)
    if (net) output = output.filter((x) => x.NetCode == net)
    if (speciesCode) output = output.filter((x) => x.SpeciesCode == speciesCode)
    if (speciesName) output = output.filter((x) => x.SpeciesName == speciesName)

    return output
  }, [batchstockcurrentharvested, farm, net, speciesCode, speciesName])

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={() => {
            refetchReport()
          }}
        >
          Get Data
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: 5 }}
          onClick={() => {
            handleSaveCsv()
          }}
        >
          Get CSV
        </Button>

        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: 5 }}
          onClick={() => {
            handleSaveIMCsv()
          }}
        >
          Get IM Mortality CSV
        </Button>
        {loadingReport && (
          <Grid item style={{ paddingTop: 20 }} xs={12} sm={12}>
            <Loading />
          </Grid>
        )}

        <Grid container spacing={3} style={{ marginTop: 20, marginBottom: 20 }}>
          <Grid item md={2} xs={3} sm={3}>
            {batchstockcurrentharvested.length > 0 && (
              <FormControl fullWidth>
                <InputLabel shrink id="farm-label">
                  Select Farm
                </InputLabel>
                <Select
                  fullWidth
                  labelId="farm-label"
                  value={farm}
                  onChange={(event) => {
                    setFarm(event.target.value)
                    setNet(undefined)
                  }}
                >
                  <MenuItem value={undefined}>All</MenuItem>
                  {filteredFarm.map((a, i) => (
                    <MenuItem value={a.FarmCode} key={`${a.id}-farm-${i}`}>
                      {a.FarmCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          {farm && (
            <Grid item md={2} xs={3} sm={3}>
              <FormControl fullWidth>
                <InputLabel shrink id="net-label">
                  Select Net
                </InputLabel>
                <Select
                  fullWidth
                  labelId="net-label"
                  value={net}
                  onChange={(event) => {
                    setNet(event.target.value)
                  }}
                >
                  <MenuItem value={undefined}>All</MenuItem>
                  {filteredNet.map((a, i) => (
                    <MenuItem value={a.NetCode} key={`${a.id}-net-${i}`}>
                      {a.NetCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {farm && (
            <Grid item md={2} xs={3} sm={3}>
              <FormControl fullWidth>
                <InputLabel shrink id="speciesCode-label">
                  Select Species Code
                </InputLabel>
                <Select
                  fullWidth
                  labelId="speciesCode-label"
                  value={speciesCode}
                  onChange={(event) => {
                    setSpeciesCode(event.target.value)
                  }}
                >
                  <MenuItem value={undefined}>All</MenuItem>
                  {filteredSpeciesCode.map((a, i) => (
                    <MenuItem
                      value={a.SpeciesCode}
                      key={`${a.id}-speciesCode-${i}`}
                    >
                      {a.SpeciesCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {farm && (
            <Grid item md={4} xs={3} sm={3}>
              <FormControl fullWidth>
                <InputLabel shrink id="speciesName-label">
                  Select Species Name
                </InputLabel>
                <Select
                  fullWidth
                  labelId="speciesName-label"
                  value={speciesName}
                  onChange={(event) => {
                    setSpeciesName(event.target.value)
                  }}
                >
                  <MenuItem value={undefined}>All</MenuItem>
                  {filteredSpeciesName.map((a, i) => (
                    <MenuItem
                      value={a.SpeciesName}
                      key={`${a.id}-speciesName-${i}`}
                    >
                      {a.SpeciesName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        {!loadingReport && (
          <Grid item xs={12} sm={12}>
            <TableHalf
              rowsPerPage={100}
              clickOnSelect={true}
              hidePagination={false}
              hideSearch={false}
              hideDelete={true}
              hideChange={true}
              selected={selected}
              setSelected={setSelected}
              tableState={setClosing}
              tableData={filteredData}
              setOpen={setOpenClosingDetail}
              tableHead={tableHead}
            ></TableHalf>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
