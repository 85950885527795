import { gql } from 'apollo-boost'

export const GET_OFFICESTAFFS = gql`
  {
    officestaffs {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      CreatedOn
      LastUpdated
      Username
      Error
    }
  }
`

export const UPDATE_OFFICESTAFF = gql`
  mutation UpdateOfficestaff(
    $id: ID
    $Email: String
    $Phone: String
    $FirstName: String
    $LastName: String
    $Address1: String
    $Address2: String
    $City: String
    $Postcode: String
    $State: String
    $Country: String
    $Password: String
  ) {
    updateofficestaff(
      id: $id
      Email: $Email
      Phone: $Phone
      FirstName: $FirstName
      LastName: $LastName
      Address1: $Address1
      Address2: $Address2
      City: $City
      Postcode: $Postcode
      State: $State
      Country: $Country
      Password: $Password
    ) {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      CreatedOn
      LastUpdated
      Username
      Error
    }
  }
`
export const INSERT_OFFICESTAFF = gql`
  mutation InsertOfficestaff(
    $Email: String
    $Phone: String
    $FirstName: String
    $LastName: String
    $Address1: String
    $Address2: String
    $City: String
    $Postcode: String
    $State: String
    $Country: String
    $Username: String
    $Department: String
    $Password: String
  ) {
    insertofficestaff(
      Email: $Email
      Phone: $Phone
      FirstName: $FirstName
      LastName: $LastName
      Address1: $Address1
      Address2: $Address2
      City: $City
      Postcode: $Postcode
      State: $State
      Country: $Country
      Password: $Password
      Username: $Username
      Department: $Department
    ) {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      CreatedOn
      LastUpdated
      Username
      Error
    }
  }
`

export const REMOVE_OFFICESTAFF = gql`
  mutation RemoveOfficestaff($id: ID!) {
    removeofficestaff(id: $id)
  }
`
