import { gql } from 'apollo-boost'

export const GET_FEEDS = gql`
  {
    feeds {
      id
      FishfeedCode
      FishfeedName
      FishfeedDesc
      KGToBags
      CostPerKg
    }
  }
`

export const REMOVE_FEED = gql`
  mutation RemoveFeed($id: ID!) {
    removefeed(id: $id)
  }
`
export const UPDATE_FEED = gql`
  mutation UpdateFeed(
    $id: ID
    $FishfeedCode: String
    $FishfeedName: String
    $FishfeedDesc: String
    $KGToBags: Float
  ) {
    updatefeed(
      id: $id
      FishfeedCode: $FishfeedCode
      FishfeedName: $FishfeedName
      FishfeedDesc: $FishfeedDesc
      KGToBags: $KGToBags
    ) {
      id
      FishfeedCode
      FishfeedName
      FishfeedDesc
      KGToBags
      CostPerKg
    }
  }
`

export const CREATE_FEED = gql`
  mutation InsertFeed(
    $FishfeedCode: String
    $FishfeedName: String
    $FishfeedDesc: String
    $KGToBags: Float
  ) {
    insertfeed(
      FishfeedCode: $FishfeedCode
      FishfeedName: $FishfeedName
      FishfeedDesc: $FishfeedDesc
      KGToBags: $KGToBags
    ) {
      id
      FishfeedCode
      FishfeedName
      FishfeedDesc
      KGToBags
      CostPerKg
    }
  }
`
