import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import ShopPaymentDisplay from './shopPaymentDisplay'
import ConfigDialog from './configDialog'
import ShopPaymentUploadDialog from './shopPaymentUploadDialog'
import { GET_SHOPCONFIG } from '../graphql/config'
import Loading from '../common/loading'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'CustomerCode',
    numeric: false,
    disablePadding: true,
    label: 'Client Code',
  },
  {
    id: 'CustomerName',
    numeric: false,
    disablePadding: true,
    label: 'Client Name',
  },
]

export default function ShopSettings(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const [formPlaceholder, setFormPlaceholder] = useState()
  const [formTitle, setFormTitle] = useState()
  const [formKey, setFormKey] = useState()
  const [formValue, setFormValue] = useState()

  const {
    loading,
    data: { shopconfig } = { shopconfig: {} },
    refetch,
    error,
  } = useQuery(GET_SHOPCONFIG)

  if (loading) return <Loading />
  if (error) return <p>API ERROR</p>

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <ShopPaymentUploadDialog
            refetch={refetch}
            open={openUpload}
            setOpen={setOpenUpload}
          />
          <ConfigDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            formTitle={formTitle}
            formPlaceholder={formPlaceholder}
            formKey={formKey}
            formValue={formValue}
            open={open}
            refetch={refetch}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <div className={classes.displayDiv}>
            <ShopPaymentDisplay
              refetch={refetch}
              setTheme={props.setTheme}
              setOpen={setOpen}
              data={shopconfig}
              setFormKey={setFormKey}
              setFormValue={setFormValue}
              setFormTitle={setFormTitle}
              setFormPlaceholder={setFormPlaceholder}
              setOpenUpload={setOpenUpload}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
