import { gql } from 'apollo-boost'

export const GET_SALES = gql`
  {
    sales {
      id
      Txdate
      Transno
      Class
      Do
      DebCode
      Name
      Batch
      Net
      Farm
      Species
      Pcs
      Kg
      RmKg
      Rm
      Size
      Staffcode
      StockCode
    }
  }
`

export const GET_SALESFRYPURCHASING = gql`
  {
    salesfrypurchasing {
      id
      PurchaseDate
      Month
      No
      Farm
      NewBatchNo
      OldBatchNo
      TypesOfFish
      PackingBoxXPcs
      TotalMortality
      NettQtyPcs
      UnitPrice
      AmountRm
      Size
      Suppliers
      Invoice
    }
  }
`
