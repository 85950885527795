import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import FarmDialog from './farmDialog'
import { GET_FARMS } from '../graphql/farm'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'FarmCode',
    label: 'Farm Code',
  },
  {
    id: 'TotalRows',
    label: 'Total Rows',
  },
  {
    id: 'ColsA',
    label: 'Columns A Side',
  },
  {
    id: 'ColsB',
    label: 'Columns B Side',
  },
  /* {
    id: 'ModifiedBy',
    logdate: 'ModifiedOn',
    log: true,
    disablePadding: true,
    label: 'Modified',
  }, */
  ,
]

export default function Farm() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [farm, setFarm] = useState()

  const {
    loading: loadingFarms,
    data: { farms } = { farms: [] },
    refetch: refetchFarms,
    error,
  } = useQuery(GET_FARMS)

  const handleClickOpen = () => {
    setFarm()
    setSelected([])
    setOpen(true)
  }

  if (loadingFarms) return <Loading />
  if (error) return <p>API ERROR</p>

  return (
    <div className={classes.root}>
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure? Product?"
        okButton="Yes"
        title="Are you sure?"
      /> */}
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        New
      </Button>
      <Button
        variant="outlined"
        color="primary"
        style={{ marginLeft: 5 }}
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchFarms()
        }}
      >
        Refresh
      </Button>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <FarmDialog
            setOpen={setOpen}
            setSelected={setSelected}
            setFarm={setFarm}
            data={farm}
            open={open}
            refetchFarms={refetchFarms}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            hidePagination={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setFarm}
            tableData={farms}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
