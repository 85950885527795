import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import { GET_CATEGORIES } from '../graphql/category'
import { CREATE_FARM, UPDATE_FARM, GET_FARMS } from '../graphql/farm'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'

import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {
  GET_STAFFS,
  CREATE_STAFF,
  UPDATE_STAFF,
  REMOVE_STAFF,
} from '../graphql/staff'
import AutocompleteField from '../common/autocompleteField'

const restApi = 'https://frapp.cloud:3670'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function StaffDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirm, setConfirm] = useState(false)

  const [farmcode, setFarmcode] = useState([])
  const [department, setDepartment] = useState()
  const [departments, setDepartments] = useState([
    { value: 'Management', name: 'Management' },
    { value: 'Farm', name: 'Farm' },
    { value: 'Farmadmin', name: 'Farm admin' },
  ])

  const {
    loading: loadingFarms,
    data: { farms } = { farms: [] },
    refetch: refetchStaff,
    error,
  } = useQuery(GET_FARMS)

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_STAFFS,
    })
    const latest = data.createstaff
    cache.writeQuery({
      query: GET_STAFFS,
      data: { staffs: [latest, ...old.staffs] },
    })

    props.setOpen(false)
    props.setSelected([latest.id])
    props.setStaff(latest)
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_STAFFS,
    })
    const latest = data.updatestaff
    const foundIndex = old.staffs.findIndex((item) => item.id === latest.id)
    old.staffs.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_STAFFS,
      data: { staffs: [...old.staffs] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setStaff()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_STAFFS,
    })
    if (data.removestaff) {
      const latest = old.staffs.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_STAFFS,
        data: { staffs: [...latest] },
      })
      props.setOpen(false)
      props.setSelected([])
      props.setStaff()
    }
  }

  const [createStaff] = useMutation(CREATE_STAFF, { update: addCache })
  const [updateStaff] = useMutation(UPDATE_STAFF, {
    update: updateCache,
  })
  const [removeStaff] = useMutation(REMOVE_STAFF, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateStaff({
        variables: {
          id: props.data.id,
          StaffID: values.StaffID.replace(/[^A-Z0-9]/gi, ''),
          Department: department,
          Password2: values.Password2,
          FarmCode: farmcode && farmcode.join(','),
        },
      })
    } else {
      createStaff({
        variables: {
          StaffID: values.StaffID.replace(/[^A-Z0-9]/gi, ''),
          Department: department,
          Password2: values.Password2,
          FarmCode: farmcode && farmcode.join(','),
        },
      })
    }
  }

  const handleClickDelete = () => {
    removeStaff({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
    props.setOpen(false)
    props.setSelected([])
    props.setStaff()
  }

  useEffect(() => {
    if (department == 'Management') {
      setFarmcode([])
    }
  }, [department])

  useEffect(() => {
    if (!props.data) return

    setFarmcode(props.data.FarmCode && props.data.FarmCode.split(','))
    setDepartment(props.data.Department)
  }, [props])

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to remove Staff?"
        okButton="Yes"
        title="Are you sure?"
      />

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="farm-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="farm-dialog">
            {props.data && 'Update Staff'}
            {!props.data && 'New Staff'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <AutocompleteField
                    inputRef={register({
                      required: 'Required',
                    })}
                    name="department"
                    label="Department"
                    value={department}
                    options={departments.map((item) => item.value)}
                    onChange={(e, value) => {
                      if (value) {
                        setDepartment(value)
                      }
                    }}
                  />
                </Grid>
              </Grid>

              {department != 'Management' && department != 'Farmadmin' && (
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        List of Farm
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={farmcode}
                        onChange={(event) => {
                          if (event.target.value) {
                            setFarmcode(event.target.value)
                          }
                        }}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {farms.map((item, i) => (
                          <MenuItem key={i} value={item.FarmCode}>
                            <Checkbox
                              checked={farmcode.indexOf(item.FarmCode) > -1}
                            />
                            <ListItemText primary={item.FarmCode} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* <AutocompleteField
                      inputRef={register({
                        required: 'Required',
                      })}
                      name="farmcode"
                      label="Farm Code"
                      value={farmcode}
                      options={farms.map((item) => item.FarmCode)}
                      onChange={(e, value) => {
                        if (value) {
                          setFarmcode(value)
                        }
                      }}
                    /> */}
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl error={errors.StaffID && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="StaffID"
                    >
                      Username
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="StaffID"
                      name="StaffID"
                      label="Staff ID"
                      defaultValue={props.data && props.data.StaffID}
                      fullWidth
                      margin="dense"
                      autoComplete="off"
                      inputProps={{
                        maxLength: 200,
                      }}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {/* {errors.StaffID && errors.StaffID.message} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <FormControl error={errors.Password2 && true} fullWidth>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="Password2"
                    >
                      Password
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="Password2"
                      name="Password2"
                      label="Password"
                      fullWidth
                      margin="dense"
                      autoComplete="off"
                      defaultValue={props.data && props.data.Password2}
                      inputProps={{
                        maxLength: 200,
                      }}
                      inputRef={register({
                        required: 'This field is Required',
                      })}
                    />

                    <FormHelperText>
                      {errors.Password2 && errors.Password2.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <React.Fragment>
                {
                  <IconButton
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setConfirm(true)
                    }}
                    edge="end"
                    aria-label="comments"
                  >
                    <DeleteIcon />
                  </IconButton>
                }
                <Button
                  margin="dense"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </React.Fragment>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
