import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import SpeciesDialog from './speciesDialog'
import { GET_SPECIES } from '../graphql/species'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'species_code',
    label: 'Species Code',
  },
  {
    id: 'species_name',
    label: 'Species Name',
  },
]

export default function Species() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [specie, setSpecie] = useState()

  const {
    loading: loadingSpecies,
    data: { species } = { species: [] },
    refetch: refetchSpecies,
    error,
  } = useQuery(GET_SPECIES)

  const handleClickOpen = () => {
    setSpecie()
    setSelected([])
    setOpen(true)
  }

  return (
    <div className={classes.root}>
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure? Product?"
        okButton="Yes"
        title="Are you sure?"
      /> */}
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        New
      </Button>
      <Button
        variant="outlined"
        color="primary"
        style={{ marginLeft: 5 }}
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchSpecies()
        }}
      >
        Refresh
      </Button>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <SpeciesDialog
            setOpen={setOpen}
            setSelected={setSelected}
            setSpecie={setSpecie}
            data={specie}
            open={open}
            refetchSpecies={refetchSpecies}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            hidePagination={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setSpecie}
            tableData={species}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
      </Grid>
      {/*  <pre>{JSON.stringify(species, null, 4)}</pre> */}
    </div>
  )
}
