import React, { useEffect, useRef, useState, Fragment } from 'react'
import {
  fade,
  makeStyles,
  withStyles,
  useTheme,
} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Cookies from 'js-cookie'
import { red } from '@material-ui/core/colors'
import Divider from '@material-ui/core/Divider'
import AutocompleteField from '../common/autocompleteField'

import { useQuery, useMutation } from '@apollo/react-hooks'

import { GET_FARMS, GET_NETS, GET_FEEDS } from '../graphql/farm'
import {
  CREATE_VIEWDATA,
  UPDATE_VIEWDATA,
  REMOVE_VIEWDATA,
} from '../graphql/viewdata'
import { GET_STAFFS } from '../graphql/staff'
import { GET_BATCHES } from '../graphql/batch'

import NetDialogSelection from './netDialogSelection'
import ConfirmationDialog from '../common/confirmationDialog'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
  table: {
    width: '90%',
  },
  tablecell: {
    overflow: 'hidden',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    padding: '5px 5px!important',
    fontSize: 13,
  },
  containerroot: {
    width: 'calc(100vw - 40px)',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    paddingTop: 0,
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
}))

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: '#f44336!important',
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button)

export default function DataEntry(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [farm, setFarm] = useState(Cookies.get('FarmCode'))
  const [feed, setFeed] = useState('')
  const [entryDate, setEntryDate] = useState()
  const [batch, setBatch] = useState()
  const [net, setNet] = useState()
  const [feedQuantity, setFeedQuantity] = useState()
  const [mortalityPcs, setMortalityPcs] = useState()
  const [mortalityKg, setMortalityKg] = useState()
  const [open, setOpen] = useState(false)
  const [pouchid, setPouchid] = useState()
  const [userFarmsDB, setUserFarmsDB] = useState([])
  const [confirm, setConfirm] = useState()
  //const [batches, setBatches] = useState([])

  const [batchSelect, setBatchSelect] = useState([])

  const {
    loading: loadingFarms,
    data: { farms } = { farms: [] },
    refetch: refetchFarms,
    error: errorFarms,
  } = useQuery(GET_FARMS)

  const {
    loading: loadingFeeds,
    data: { feeds } = { feeds: [] },
    refetch: refetchFeeds,
    error: errorFeeds,
  } = useQuery(GET_FEEDS)

  const {
    loading: loadingNets,
    data: { nets } = { nets: [] },
    refetch: refetchNets,
    error: errorNets,
  } = useQuery(GET_NETS)

  const {
    loading: loadingBatch,
    data: { batches } = { batches: [] },
    refetch: refetchBatch,
    error,
  } = useQuery(GET_BATCHES)

  useEffect(() => {
    if (nets[0]) {
      const netvalue = nets.find((a) => a.NetCode == net && a.FarmCode == farm)

      if (netvalue && netvalue.Batches) {
        //setBatches(netvalue.Batches.split(','))
      }
    }
  }, [net])

  useEffect(() => {
    if (farm)
      setBatchSelect(
        batches.filter(
          (a) => a.FarmCodes && a.FarmCodes.split(',').find((b) => b == farm)
        )
      )
  }, [farm])

  const addCache = (cache, { data }) => {
    props.refetch()
    props.setOpen(false)
    enqueueSnackbar('Saved.', {
      variant: 'success',
    })
  }

  const [createViewData] = useMutation(CREATE_VIEWDATA, { update: addCache })

  const updateCache = (cache, { data }) => {
    props.refetch()
    props.setOpen(false)
    enqueueSnackbar('Saved.', {
      variant: 'success',
    })
  }

  const [updateViewData] = useMutation(UPDATE_VIEWDATA, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    props.refetch()
    props.setOpen(false)
    enqueueSnackbar('Deleted.', {
      variant: 'success',
    })
  }

  const [removeViewData] = useMutation(REMOVE_VIEWDATA, {
    update: deleteCache,
  })

  const onSubmit = () => {
    if (!entryDate || !farm || (!net && !batch) || !feed || !feedQuantity) {
      enqueueSnackbar('Please fill in the form', {
        variant: 'error',
      })
      return
    }

    if (props.data) {
      updateViewData({
        variables: {
          id: props.data && props.data.id,
          Batch: batch,
          CreatedOn: entryDate,
          FarmCode: farm,
          NetCode: net,
          FishfeedCode: feed,
          MortalityKg: (mortalityKg && parseFloat(mortalityKg)) || 0,
          MortalityPcs: (mortalityPcs && parseInt(mortalityPcs)) || 0,
          Qty: (feedQuantity && parseFloat(feedQuantity)) || 0,
        },
      })
    } else {
      createViewData({
        variables: {
          Batch: batch,
          CreatedOn: entryDate,
          FarmCode: farm,
          NetCode: net,
          FishfeedCode: feed,
          MortalityKg: (mortalityKg && parseFloat(mortalityKg)) || 0,
          MortalityPcs: (mortalityPcs && parseInt(mortalityPcs)) || 0,
          Qty: (feedQuantity && parseFloat(feedQuantity)) || 0,
        },
      })
    }
  }

  const handleFormReset = () => {
    //setBatches([])
    setFarm('')
    setNet('')
    setBatch('')
    setFeed('')
    setFeedQuantity('')
    setMortalityKg('')
    setMortalityPcs('')
    setPouchid()
    setEntryDate(new Date())
  }

  useEffect(() => {
    if (!props.data) {
      handleFormReset()
      return
    }

    setFarm(props.data.FarmCode)
    setNet(props.data.NetCode)
    setBatch(props.data.Batch)
    setFeed(props.data.FishfeedCode)
    setFeedQuantity(props.data.Qty)
    setMortalityKg(props.data.MortalityKg)
    setMortalityPcs(props.data.MortalityPcs)
    setEntryDate(props.data.CreatedOn)
  }, [props])

  const handleClickDelete = () => {
    removeViewData({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
    props.setOpen(false)
    props.setSelected([])
  }

  if (!Cookies.get('signedin')) return <Fragment></Fragment>

  return (
    <div>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to remove"
        okButton="Yes"
        title="Are you sure?"
      />
      <NetDialogSelection
        key={new Date() + Math.random()}
        setOpen={setOpen}
        setNet={setNet}
        data={feed}
        open={open}
        feed={feed}
        farms={farms}
        nets={nets}
        farm={farm}
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="farm-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="farm-dialog">
            {props.data && 'Update Data'}
            {!props.data && 'New Data'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <form onSubmit={onSubmit}>
              {/* <input
          autoFocus
          className="new-todo"
          onKeyDown={({ key, target }) => {
            const title = target.value.trim()
            if (key === 'Enter' && title) {
              post({ title, timestamp: Date.now() })
              target.value = ''
            }
          }}
          placeholder="What needs to be done?"
          type="text"
        /> */}

              {/* <UpdateMobile /> */}
              <Grid
                style={{ paddingTop: 0, marginTop: 0 }}
                spacing={3}
                container
                classes={{ root: classes.rootContainer }}
              >
                <Grid
                  style={{ paddingTop: 0, marginTop: 0 }}
                  item
                  xs={6}
                  md={4}
                >
                  <FormControl
                    style={{ marginTop: 16 }}
                    /* error={errors.EntryDate && true} */ fullWidth
                  >
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="EntryDate"
                    >
                      Date
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="EntryDate"
                      name="EntryDate"
                      type="date"
                      label="EntryDate"
                      value={entryDate}
                      margin="dense"
                      onChange={(e) => {}}
                      autoComplete="off"
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(e) => {
                        setEntryDate(e.target.value)
                      }}
                      /* inputRef={register({
                  required: 'This field is Required'
                })} */
                    />

                    <FormHelperText>
                      {/* {errors.EntryDate && errors.EntryDate.message} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  style={{ paddingTop: 0, marginTop: 0 }}
                  item
                  xs={6}
                  md={8}
                >
                  <AutocompleteField
                    name="Farm"
                    label="Farm"
                    value={farm || ''}
                    options={farms.map((item) => item.FarmCode)}
                    onChange={(e, value) => {
                      if (value) {
                        setFarm(value)
                        setBatch('')
                        setNet('')
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <br />

                  <FormControl /* error={errors.Net && true} */>
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="Net"
                    >
                      Net
                    </InputLabel>
                    <InputBase
                      disabled={batch}
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="Net"
                      name="Net"
                      label="Net"
                      value={net}
                      margin="dense"
                      autoComplete="off"
                      inputProps={{
                        maxLength: 200,
                        readOnly: true,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              if (!batch) setOpen(true)
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      /* inputRef={register({
                  required: 'This field is Required'
                })} */
                    />
                    <FormHelperText>
                      {/* {errors.Net && errors.Net.message} */}
                    </FormHelperText>
                  </FormControl>
                  {/* <IconButton
                style={{ marginTop: 10 }}
                onClick={() => {
                  setOpen(true)
                }}
                aria-label="delete"
              >
                <SearchIcon  />
              </IconButton> */}
                </Grid>

                <Grid item xs={6} md={6}>
                  <AutocompleteField
                    name="Batch"
                    label="Batch"
                    value={batch || ''}
                    options={batches.map((item) => item.BatchCode)}
                    onChange={(e, value) => {
                      if (value) {
                        setBatch(value)
                        setNet('')
                      }
                    }}
                  />
                </Grid>

                {/* <Grid item xs={1} md={6}></Grid> */}
              </Grid>
              {/* 
          <Grid container classes={{ root: classes.rootContainer }}>
            <Grid item xs={12} md={12}>
              <div style={{ overflow: 'hidden' }}>
                <TableContainer
                  component={Paper}
                  classes={{ root: classes.containerroot }}
                >
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell classes={{ root: classes.tablecell }}>
                          Batch
                        </TableCell>
                        <TableCell classes={{ root: classes.tablecell }}>
                          Qty
                        </TableCell>
                        <TableCell classes={{ root: classes.tablecell }}>
                          Fish Type
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {batches.map((a, i) => (
                        <TableRow key={i}>
                          <TableCell classes={{ root: classes.tablecell }}>
                            {a.split('|')[0]}
                          </TableCell>
                          <TableCell classes={{ root: classes.tablecell }}>
                            {a.split('|')[1].split(' pcs')[0]}
                          </TableCell>
                          <TableCell classes={{ root: classes.tablecell }}>
                            {a.split('|')[3]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                </TableContainer>
              </div>
            </Grid>
          </Grid> */}

              <Grid container>
                <Grid item xs={12} md={12}>
                  <AutocompleteField
                    name="Feed"
                    label="Feed"
                    value={feed || ''}
                    options={feeds.map((item) => item.FishfeedCode)}
                    onChange={(e, value) => {
                      if (value) {
                        setFeed(value)
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <br />

              <Grid container classes={{ root: classes.rootContainer }}>
                <Grid item xs={4} md={6}>
                  <FormControl
                    /* error={errors.FeedQuantity && true}  */ fullWidth
                  >
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="FeedQuantity"
                    >
                      Feed Qty
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="FeedQuantity"
                      name="FeedQuantity"
                      label="Feed Quantity"
                      fullWidth
                      margin="dense"
                      value={feedQuantity}
                      onChange={(e) => {
                        setFeedQuantity(e.target.value)
                      }}
                      autoComplete="off"
                      inputProps={{
                        maxLength: 200,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span style={{ color: '#0aa44a' }}>Kg</span>
                        </InputAdornment>
                      }
                      /* inputRef={register({
                  required: 'This field is Required'
                })} */
                    />
                    <FormHelperText>
                      {/* {errors.FeedQuantity && errors.FeedQuantity.message} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />

              <Grid
                container
                classes={{ root: classes.rootContainer }}
                spacing={3}
              >
                <Grid item xs={5} md={6}>
                  <FormControl
                    /* error={errors.FeedQuantity && true}  */ fullWidth
                  >
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="MortalityPcs"
                    >
                      Mortality Pcs
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="MortalityPcs"
                      name="MortalityPcs"
                      label="Mortality Pcs"
                      fullWidth
                      margin="dense"
                      value={mortalityPcs}
                      onChange={(e) => {
                        setMortalityPcs(e.target.value)
                      }}
                      autoComplete="off"
                      inputProps={{
                        maxLength: 200,
                      }}
                      /* endAdornment={
                    <InputAdornment position="end">
                      <span style={{ color: '#0aa44a' }}>Pcs</span>
                    </InputAdornment>
                  } */
                      /* inputRef={register({
                  required: 'This field is Required'
                })} */
                    />
                    <FormHelperText>
                      {/* {errors.FeedQuantity && errors.FeedQuantity.message} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={5} md={6}>
                  <FormControl
                    /* error={errors.FeedQuantity && true}  */ fullWidth
                  >
                    <InputLabel
                      classes={{ root: classes.rootInputlabel }}
                      shrink
                      htmlFor="MortalityKg"
                    >
                      Mortality KG
                    </InputLabel>
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      id="MortalityKg"
                      name="MortalityKg"
                      label="Mortality KG"
                      fullWidth
                      margin="dense"
                      value={mortalityKg}
                      onChange={(e) => {
                        setMortalityKg(e.target.value)
                      }}
                      autoComplete="off"
                      inputProps={{
                        maxLength: 200,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span style={{ color: '#0aa44a' }}>Kg</span>
                        </InputAdornment>
                      }
                      /* inputRef={register({
                  required: 'This field is Required'
                })} */
                    />
                    <FormHelperText>
                      {/* {errors.FeedQuantity && errors.FeedQuantity.message} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <React.Fragment>
              <IconButton
                style={{ marginRight: 10 }}
                onClick={() => {
                  setConfirm(true)
                }}
                edge="end"
                aria-label="comments"
              >
                <DeleteIcon />
              </IconButton>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onSubmit()
                }}
              >
                Save
              </Button>
              <Button
                type="button"
                onClick={handleFormReset}
                variant="outlined"
                color="primary"
                style={{ marginLeft: 5 }}
              >
                Reset
              </Button>
            </React.Fragment>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </div>
  )
}
