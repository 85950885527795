import { gql } from 'apollo-boost'

export const GET_VIEWDATAS = gql`
  query ViewDatas($farmcode: String, $from: Date, $to: Date) {
    viewdatas(farmcode: $farmcode, from: $from, to: $to) {
      id
      Row
      CreatedBy
      CreatedOn
      FarmCode
      NetCode
      FishfeedCode
      Qty
      Pcs
      Batch
      CostPerKg
      MortalityKg
      MortalityPcs
    }
  }
`

export const GET_VIEWDATASUMMARY = gql`
  query ViewDataSummary($farmcode: String, $from: Date, $to: Date) {
    viewdatasummary(farmcode: $farmcode, from: $from, to: $to) {
      id
      Row
      CreatedBy
      CreatedOn
      FarmCode
      NetCode
      FishfeedCode
      Qty
      Pcs
      Batch
      CostPerKg
      MortalityKg
      MortalityPcs
    }
  }
`

export const CREATE_VIEWDATA = gql`
  mutation CreateViewData(
    $CreatedOn: Date
    $FarmCode: String
    $Batch: String
    $NetCode: String
    $FishfeedCode: String
    $Qty: Float
    $MortalityKg: Float
    $MortalityPcs: Int
  ) {
    createviewdata(
      CreatedOn: $CreatedOn
      Batch: $Batch
      FarmCode: $FarmCode
      NetCode: $NetCode
      FishfeedCode: $FishfeedCode
      Qty: $Qty
      MortalityKg: $MortalityKg
      MortalityPcs: $MortalityPcs
    ) {
      id
      Row
      CreatedBy
      CreatedOn
      FarmCode
      NetCode
      FishfeedCode
      Qty
      Pcs
      Batch
      CostPerKg
      MortalityKg
      MortalityPcs
    }
  }
`

export const UPDATE_VIEWDATA = gql`
  mutation UpdateViewData(
    $id: ID
    $CreatedOn: Date
    $FarmCode: String
    $Batch: String
    $NetCode: String
    $FishfeedCode: String
    $Qty: Float
    $MortalityKg: Float
    $MortalityPcs: Int
  ) {
    updateviewdata(
      id: $id
      CreatedOn: $CreatedOn
      Batch: $Batch
      FarmCode: $FarmCode
      NetCode: $NetCode
      FishfeedCode: $FishfeedCode
      Qty: $Qty
      MortalityKg: $MortalityKg
      MortalityPcs: $MortalityPcs
    ) {
      id
      Row
      CreatedBy
      CreatedOn
      FarmCode
      NetCode
      FishfeedCode
      Qty
      Pcs
      Batch
      CostPerKg
      MortalityKg
      MortalityPcs
    }
  }
`

export const REMOVE_VIEWDATA = gql`
  mutation RemoveViewData($id: ID!) {
    removeviewdata(id: $id)
  }
`
