import { gql } from 'apollo-boost'

export const GET_NETS = gql`
  query Nets($FarmCode: String) {
    nets(FarmCode: $FarmCode) {
      id
      FarmCode
      FarmID
      SubFarmID
      NetCode
      NetName
      NetDesc
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      Remarks
      NetRows
      NetCols
      NetColor
      Active
      MainNetID
      MainNetCode
      Prefix
      Row
      Col
      Batch
      Pcs
      NetSize
      Batches
    }
  }
`

export const GET_MAINNET_SELECT = gql`
  query MainNetSelect($FarmCode: String) {
    mainnetselect(FarmCode: $FarmCode) {
      NetCode
    }
  }
`

export const REMOVE_NET = gql`
  mutation RemoveNet($id: ID!) {
    removenet(id: $id)
  }
`

export const MOVE_NET = gql`
  mutation MoveNet(
    $id: String
    $BatchCode: String
    $FarmCode: String
    $OldNetCode: String
    $NewNetCode: String
  ) {
    movenet(
      id: $id
      BatchCode: $BatchCode
      FarmCode: $FarmCode
      OldNetCode: $OldNetCode
      NewNetCode: $NewNetCode
    )
  }
`

export const SET_HARVEST_NET = gql`
  mutation SetHarvestNet(
    $id: String
    $BatchCode: String
    $FarmCode: String
    $NetCode: String
    $Harvested: Boolean
  ) {
    setharvestnet(
      id: $id
      BatchCode: $BatchCode
      FarmCode: $FarmCode
      NetCode: $NetCode
      Harvested: $Harvested
    )
  }
`

export const UPDATE_NET = gql`
  mutation UpdateNet(
    $id: ID!
    $FarmCode: String
    $FarmID: ID
    $SubFarmID: ID
    $NetCode: String
    $NetName: String
    $NetDesc: String
    $Remarks: String
    $NetRows: Int
    $NetCols: Int
    $NetColor: String
    $MainNetID: ID
    $MainNetCode: String
    $Prefix: String
    $Row: Int
    $Col: Int
    $Batch: Int
    $Pcs: Float
  ) {
    updatenet(
      id: $id
      FarmCode: $FarmCode
      FarmID: $FarmID
      SubFarmID: $SubFarmID
      NetCode: $NetCode
      NetName: $NetName
      NetDesc: $NetDesc
      Remarks: $Remarks
      NetRows: $NetRows
      NetCols: $NetCols
      NetColor: $NetColor
      MainNetID: $MainNetID
      MainNetCode: $MainNetCode
      Prefix: $Prefix
      Row: $Row
      Col: $Col
      Batch: $Batch
      Pcs: $Pcs
    ) {
      id
      FarmCode
      FarmID
      SubFarmID
      NetCode
      NetName
      NetDesc
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      Remarks
      NetRows
      NetCols
      NetColor
      Active
      MainNetID
      MainNetCode
      Prefix
      Row
      Col
      Batch
      Pcs
      NetSize
      Batches
    }
  }
`

export const CREATE_NET = gql`
  mutation CreateNet(
    $FarmCode: String
    $FarmID: ID
    $SubFarmID: ID
    $NetCode: String
    $NetName: String
    $NetDesc: String
    $Remarks: String
    $NetRows: Int
    $NetCols: Int
    $NetColor: String
    $MainNetID: ID
    $MainNetCode: String
    $Prefix: String
    $Row: Int
    $Col: Int
    $Batch: Int
    $Pcs: Float
  ) {
    insertnet(
      FarmCode: $FarmCode
      FarmID: $FarmID
      SubFarmID: $SubFarmID
      NetCode: $NetCode
      NetName: $NetName
      NetDesc: $NetDesc
      Remarks: $Remarks
      NetRows: $NetRows
      NetCols: $NetCols
      NetColor: $NetColor
      MainNetID: $MainNetID
      MainNetCode: $MainNetCode
      Prefix: $Prefix
      Row: $Row
      Col: $Col
      Batch: $Batch
      Pcs: $Pcs
    ) {
      id
      FarmCode
      FarmID
      SubFarmID
      NetCode
      NetName
      NetDesc
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      Remarks
      NetRows
      NetCols
      NetColor
      Active
      MainNetID
      MainNetCode
      Prefix
      Row
      Col
      Batch
      Pcs
      NetSize
      Batches
    }
  }
`
