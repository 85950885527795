import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import NetDialog from './netDialog'
import { GET_FARMS } from '../graphql/farm'
import { GET_NETS } from '../graphql/net'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InputAdornment from '@material-ui/core/InputAdornment'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  /* {
    id: 'IDSKU',
    label: 'SKU',
  }, id, FarmCode, FarmID, SubFarmID, NetCode, NetName, NetDesc,
   CreatedBy, CreatedOn, ModifiedBy, ModifiedOn, Remarks, NetRows,
    NetCols, NetColor, Active, MainNetID, MainNetCode, Prefix, Row, Col*/

  /* {
    id: 'FarmCode',
    label: 'Farm Code',
  }, */
  {
    id: 'NetCode',
    label: 'Net Code',
  } /* ,
  {
    id: 'NetSize',
    label: 'Net Size',
  } */,
  /* {
    batches: 'SpeciesName',
    label: 'Species Name',
  }, */
  {
    batches: 'Batches',
    label: 'Batches',
  },
  /* {
    id: 'ModifiedBy',
    logdate: 'ModifiedOn',
    log: true,
    disablePadding: true,
    label: 'Modified',
  }, */
]

export default function Farm() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [farm, setFarm] = useState('BA')
  const [net, setNet] = useState()

  const {
    loading: loadingFarms,
    data: { farms } = { farms: [] },
    refetch: refetchFarms,
  } = useQuery(GET_FARMS)

  const {
    loading: loadingNets,
    data: { nets } = { nets: [] },
    refetch: refetchNets,
  } = useQuery(GET_NETS, {
    variables: {
      FarmCode: farm,
    },
    skip: !farm,
  })

  const handleClickOpen = () => {
    setNet()
    setSelected([])
    setOpen(true)
  }

  if (loadingFarms) return <Loading />

  return (
    <div className={classes.root}>
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure? Product?"
        okButton="Yes"
        title="Are you sure?"
      /> */}

      {!loadingFarms && (
        <Grid container classes={{ root: classes.rootContainer }}>
          <Grid item xs={12} md={2}>
            <FormControl /* error={errors.Feed && true} */ fullWidth>
              <InputLabel
                classes={{ root: classes.rootInputlabel }}
                shrink
                htmlFor="Farm"
              >
                Farm
              </InputLabel>
              <Select
                labelId="Farm"
                id="Farm"
                name="Farm"
                value={farm}
                onChange={(e) => {
                  setFarm(e.target.value)
                }}
                input={
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                  />
                }
                /*  inputRef={register({
              required: 'This field is Required'
            })} */
              >
                {farms.map((a, i) => (
                  <MenuItem key={i} value={a.FarmCode}>
                    {a.FarmCode}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {/* {errors.Feed && errors.Feed.message} */}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}

      {farm && (
        <React.Fragment>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            New
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 5 }}
            startIcon={<RefreshIcon />}
            onClick={() => {
              refetchNets({
                FarmCode: farm,
              })
            }}
          >
            Refresh
          </Button>

          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <NetDialog
                setOpen={setOpen}
                setSelected={setSelected}
                setNet={setNet}
                data={net}
                open={open}
                farm={farm}
                nets={nets}
                farmData={farms.find((a) => a.FarmCode == farm)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TableHalf
                clickOnSelect={true}
                hidePagination={true}
                hideChange={true}
                hideDelete={true}
                setConfirm={setConfirm}
                selected={selected}
                setSelected={setSelected}
                tableState={setNet}
                tableData={nets}
                setOpen={setOpen}
                tableHead={tableHead}
              ></TableHalf>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
