import { gql } from 'apollo-boost'

export const GET_FARMS = gql`
  {
    farms {
      id
      FarmCode
      FarmName
      FarmDesc
      RowPrefix
      TotalRows
      TotalCols
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      ColsA
      ColsB
    }
  }
`

export const GET_FARM = gql`
  query Farm($FarmCode: String) {
    farm(FarmCode: $FarmCode) {
      id
      FarmCode
      FarmName
      FarmDesc
      RowPrefix
      TotalRows
      TotalCols
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      ColsA
      ColsB
    }
  }
`

export const REMOVE_FARM = gql`
  mutation RemoveFarm($id: ID!) {
    removefarm(id: $id)
  }
`

export const UPDATE_FARM = gql`
  mutation UpdateFarm(
    $id: ID!
    $FarmCode: String
    $FarmName: String
    $FarmDesc: String
    $RowPrefix: String
    $TotalRows: Int
    $TotalCols: Int
    $ColsA: Int
    $ColsB: Int
  ) {
    updatefarm(
      id: $id
      FarmCode: $FarmCode
      FarmName: $FarmName
      FarmDesc: $FarmDesc
      RowPrefix: $RowPrefix
      TotalRows: $TotalRows
      TotalCols: $TotalCols
      ColsA: $ColsA
      ColsB: $ColsB
    ) {
      id
      FarmCode
      FarmName
      FarmDesc
      RowPrefix
      TotalRows
      TotalCols
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      ColsA
      ColsB
    }
  }
`

export const CREATE_FARM = gql`
  mutation CreateFarm(
    $FarmCode: String
    $FarmName: String
    $FarmDesc: String
    $RowPrefix: String
    $TotalRows: Int
    $TotalCols: Int
    $ColsA: Int
    $ColsB: Int
  ) {
    insertfarm(
      FarmCode: $FarmCode
      FarmName: $FarmName
      FarmDesc: $FarmDesc
      RowPrefix: $RowPrefix
      TotalRows: $TotalRows
      TotalCols: $TotalCols
      ColsA: $ColsA
      ColsB: $ColsB
    ) {
      id
      FarmCode
      FarmName
      FarmDesc
      RowPrefix
      TotalRows
      TotalCols
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      ColsA
      ColsB
    }
  }
`

export const GET_NETS = gql`
  query Nets($FarmCode: String) {
    nets(FarmCode: $FarmCode) {
      id
      FarmCode
      FarmID
      SubFarmID
      NetCode
      NetName
      NetDesc
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
      Remarks
      NetRows
      NetCols
      NetColor
      Active
      MainNetID
      MainNetCode
      Prefix
      Row
      Col
      Batch
      Pcs
      NetSize
      Batches
    }
  }
`

export const GET_FEEDS = gql`
  {
    feeds {
      id
      FishfeedCode
      FishfeedName
      FishfeedDesc
    }
  }
`
