import { gql } from 'apollo-boost'

export const GET_STAFFS = gql`
  {
    staffs {
      id
      StaffID
      StaffName
      Department
      Password2
      FarmCode
    }
  }
`

export const CREATE_STAFF = gql`
  mutation CreateStaff(
    $StaffID: String
    $Department: Date
    $Password2: String
    $FarmCode: String
  ) {
    createstaff(
      StaffID: $StaffID
      Password2: $Password2
      Department: $Department
      FarmCode: $FarmCode
    ) {
      id
      StaffID
      StaffName
      Department
      Password2
      FarmCode
    }
  }
`

export const UPDATE_STAFF = gql`
  mutation UpdateStaff(
    $id: ID
    $StaffID: String
    $Department: Date
    $Password2: String
    $FarmCode: String
  ) {
    updatestaff(
      id: $id
      StaffID: $StaffID
      Password2: $Password2
      Department: $Department
      FarmCode: $FarmCode
    ) {
      id
      StaffID
      StaffName
      Department
      Password2
      FarmCode
    }
  }
`

export const REMOVE_STAFF = gql`
  mutation RemoveStaff($id: ID!) {
    removestaff(id: $id)
  }
`
